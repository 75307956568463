import Grid from "@mui/material/Grid";
import * as React from "react";
import WallTile from "../particles/Home/WallTile";
import QuickSessionTile from "../particles/Home/QuickSessionTile";

export function Home() {
    return <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={7} sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                overflowY: "auto"
            }}>
                <QuickSessionTile />
            </Grid>
            <WallTile/>
        </Grid>
    </>
}
