import {useSnackbar} from "notistack";
import {Blueprint} from "../../../../functions/entities/Blueprint";
import CenteredLoadingCircle from "../../loading/CenteredCircularProgress";
import {BlueprintCard} from "./Card";
import useGetCollection from "../../../../functions/hooks/useGetObject";

export function FactoryBlueprints({factoryId}: { factoryId: string | number }) {
    const {enqueueSnackbar} = useSnackbar()
    const {loading, data: blueprints, error, errorMsg} = useGetCollection<Blueprint>(`/Factory/Blueprints/${factoryId}`)
    if (error) enqueueSnackbar(errorMsg, {variant: "error"})

    if (loading) return <CenteredLoadingCircle/>
    let counter = 0
    return <div style={{marginTop: 10}}>
        <div className={"flexElements"}>
            {blueprints.map(e => <BlueprintCard factoryId={factoryId} key={++counter} blueprint={e}/>)}
        </div>
    </div>
}
