import Newsletter, {defaultNewsletter} from "../../../functions/entities/Newsletter";
import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";
import Error500 from "../Errors/500";
import {useSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import {beautyDateLong} from "../../../functions/date";
import GoBackButton from "../../particles/Buttons/GoBackButton";
import ModifyButton from "../../particles/Buttons/ModifyButton";
import RefreshButton from "../../particles/Buttons/RefreshButton";
import DistributePanel from "../../particles/Newsletter/DistributePanel";
import {useGetSingle} from "../../../functions/hooks/useGetObject";

export default function Get() {
    const {enqueueSnackbar} = useSnackbar()
    const {letterId} = useParams<{letterId:string}>()
    const {data, loading, error, errorMsg, refresh} = useGetSingle<Newsletter>(`/Newsletter/Get/${letterId}`, defaultNewsletter())

    if (loading) return <CenteredLoadingCircle/>
    if (error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
        return <Error500/>
    }

    return <>
        <div className={"flexElements spaceBetweenElements"}>
            <div>
                <GoBackButton path={"/Newsletters"} />
                <ModifyButton path={`/Newsletters/Modify/${letterId}`} />
                <RefreshButton refresh={refresh} />
            </div>
            <div>
                {data.distributed === null && data.content.length > 11 && <DistributePanel refreshPage={refresh} letterId={letterId+""} />}
            </div>
        </div>
        <Box component={Paper} sx={{p: 3, px: 7}}>
            <Typography variant={"h4"}>{data.subject} <small>#{data.id}</small></Typography>
            <Typography variant={"subtitle2"}>{data.distributed === null ? 'Created' : 'Distributed'} {beautyDateLong(data.distributed ?? data.dateTime)}</Typography>
            <Box sx={{mt:4}}>
                {data.content.length < 11
                    ? 'No content has been written yet.'
                    : <div className="content" dangerouslySetInnerHTML={{__html: data.content}}></div>}
            </Box>
        </Box>
    </>
}
