import {useParams} from "react-router-dom";
import useDocumentTitle from "../../../functions/hooks/useDocumentTitle";
import Error404 from "../Errors/404";
import useFactories from "../../../functions/hooks/useFactories";
import FactoryDetailOptions from "../../particles/Factories/FactoryDetailOptions";
import {FactoryDetails} from "../../particles/Factories/FactoryDetails";
import {FactoryBlueprints} from "../../particles/Factories/Blueprint/List";
import FactoryEmployees from "../../particles/Factories/FactoryEmployees";

export default function Get() {
    const { id } = useParams<{ id:string }>();
    const title = useDocumentTitle()
    const factoryContext = useFactories()

    const factoryIndex = factoryContext.factories.findIndex(e => e.id+"" === id)
    if(factoryIndex < 0) {
        title.update("Error 404")
        return <Error404 />
    }

    return <>
        <FactoryDetailOptions factory={factoryContext.factories[factoryIndex]} />
        <FactoryDetails factory={factoryContext.factories[factoryIndex]} />
        <FactoryBlueprints factoryId={id+""} />
        <FactoryEmployees factoryId={id+""} />
    </>
}
