import {useParams} from "react-router-dom";
import useDocumentTitle from "../../../functions/hooks/useDocumentTitle";
import useEmployees from "../../../functions/hooks/useEmployees";
import Error404 from "../Errors/404";
import {EmployeeDetailOptions} from "../../particles/Employees/EmployeeDetailOptions";
import {EmployeeDetails} from "../../particles/Employees/EmployeeDetails";

export default function Get() {
    const { id } = useParams<{ id:string }>();
    const title = useDocumentTitle()
    const employeeContext = useEmployees()

    const employeeIndex = employeeContext.employees.findIndex(e => e.id+"" === id)
    if(employeeIndex < 0) {
        title.update("Error 404")
        return <Error404 />
    }

    return <>
        <EmployeeDetailOptions employee={employeeContext.employees[employeeIndex]} />
        <br />
        <EmployeeDetails employee={employeeContext.employees[employeeIndex]} />
    </>
}
