import useCloud from "../../../functions/hooks/useCloud";
import Section from "./Section";
import {distinct} from "../../../functions/array";
import FileCard from "./FileCard";
import UploadFileButton, {UploadFileButtonToNewCategory} from "./UploadFileButton";
import {useState} from "react";
import TextField from "@mui/material/TextField";
import {filterFile} from "../../../functions/entities/File";
import NoResults from "../Other/NoResults";

export default function FilesBridge() {
    const {files} = useCloud()
    const [filter, setFilter] = useState("")

    const filteredFiles = files.filter(e => filterFile(e, filter))

    return <>
        <TextField sx={{width: "100%"}} placeholder={"Insert a file name to filter"} autoComplete='off' value={filter} onChange={e => setFilter(e.target.value)} />
        <Section title={"Root"} visible={true} actionArea={<>
            <UploadFileButton category={null} />
            <UploadFileButtonToNewCategory />
        </>}>
            {filteredFiles.filter(e => e.category === null).map(e =>
                <FileCard file={e} />)}
        </Section>
        {filteredFiles.length === 0 && <NoResults message={"Woops, this place is empty!"} />}
        {distinct(filteredFiles
            .map(e => e.category))
            .sort()
            .filter(e => e !== null)
            .map(category => <Section key={category} title={category ?? "undefined"} visible={true} actionArea={<ActionArea category={category} />}>
                {files.filter(e => e.category === category).map(e =>
                <FileCard file={e} />)}
            </Section>)}
    </>
}

function ActionArea({category}:{category:string|null}) {
    return <>
        <UploadFileButton category={category} />
    </>
}