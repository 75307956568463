import PendingComplaints from "../../particles/Complaints/Pending";
import ComplaintsTable from "../../particles/Complaints/Table";
import GoBackButton from "../../particles/Buttons/GoBackButton";
import useAuth from "../../../functions/hooks/useAuth";
import {handleComplaints} from "../../../functions/accessDefined";
import Error400 from "../Errors/400";

export default function All() {
    const auth = useAuth()

    if(!auth.isAuthorized(handleComplaints())) return <Error400 />

    return <>
        <div className={"flexElements spaceBetweenElements"}>
            <GoBackButton path={"/HR/Complaints"} />
        </div>
        <PendingComplaints />
        <ComplaintsTable />
    </>
}
