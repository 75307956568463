import {DateOnly, defaultDateOnly} from "../date";

export interface Employee {
    id: string
    firstname: string
    lastname: string
    email: string
    phone: string
    dateOfBirth: DateOnly
    profilePicture: string | null
}

export function defaultEmployee() : Employee {
    return {id: "", firstname: "", lastname: "", phone: "", email: "", profilePicture: "", dateOfBirth: defaultDateOnly()}
}

export function filterEmployee(filterUsed:string, employee:Employee) : boolean {
    const filter = filterUsed.toLowerCase()
    if((employee.id+"").toLowerCase().includes(filter.toLowerCase())) return true
    if((employee.firstname+"").toLowerCase().includes(filter.toLowerCase())) return true
    if((employee.lastname+"").toLowerCase().includes(filter.toLowerCase())) return true
    if(employeeFullName(employee).toLowerCase().includes(filter.toLowerCase())) return true
    return (employee.phone + "").toLowerCase().includes(filter.toLowerCase());

}

export function employeeFullName(employee:Employee) : string {
    return `${employee.firstname} ${employee.lastname}`
}

export function employeeInitials(employee:Employee) :string {
    return `${employee.firstname[0] ?? '?'}${employee.lastname[0] ?? '?'}`
}