import Typography from "@mui/material/Typography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";
import {useState} from "react";
import {Pagination, TextField} from "@mui/material";
import {useSnackbar} from "notistack";
import NoResults from "../Other/NoResults";
import Box from "@mui/material/Box";
import {tablePages} from "../../../functions/table";
import {handleRows} from "../../../functions/tableHandles";
import Complaint, {filterComplaint, getComplaintStatus} from "../../../functions/entities/Complaint";
import LoadingTable from "../loading/Table";
import Error500 from "../../pages/Errors/500";
import {employeeFullName} from "../../../functions/entities/Employee";
import {beautyDate} from "../../../functions/date";
import Tooltip from "@mui/material/Tooltip";
import MouseIcon from '@mui/icons-material/Mouse';
import useGetCollection, {useGetSingle} from "../../../functions/hooks/useGetObject";

export default function ComplaintsTable() {
    const {enqueueSnackbar} = useSnackbar()
    const [page, setPage] = useState(1)
    const [rows, setRows] = useState(10)
    const [filter, setFilter] = useState('')
    const {
        loading: loading2,
        error: error2,
        errorMsg: errorMsg2,
        data: totalRows
    } = useGetSingle<number>(`/Complaint/Count`, -1)
    const {loading, error, errorMsg, data} = useGetCollection<Complaint>(`/Complaint/Get/${page}/${rows}`)

    if(loading || loading2) return <>
        <AllComplaintsHeader setRows={setRows} handleRows={handleRows} rows={rows} setFilter={setFilter} filter={filter} totalRows={0} />
        <LoadingTable columns={4} rows={rows} />
    </>

    if(error2) {
        enqueueSnackbar(errorMsg2, {variant: "error"})
        return <Error500 />
    }

    if(error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
        return <Error500 />
    }

    function handlePage(value: number) {
        if (value < 0) return;
        setPage(value)
    }

    const filtered = data.filter(e => filterComplaint(filter, e))

    return <>
        <AllComplaintsHeader setRows={setRows} handleRows={handleRows} rows={rows} setFilter={setFilter} filter={filter} totalRows={filtered.length} />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="complaints">
                <TableHead>
                    <TableRow>
                        <TableCell>Complainant</TableCell>
                        <TableCell>Accused</TableCell>
                        <TableCell>Verdict</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Reason</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(e => <TableRow key={e.id}>
                        <TableCell>{employeeFullName(e.complainant)}</TableCell>
                        <TableCell>{employeeFullName(e.accused)}</TableCell>
                        <TableCell><Tooltip title={e.verdictNotes ?? ''}><div>{getComplaintStatus(e.status)}</div></Tooltip></TableCell>
                        <TableCell>{beautyDate(e.created)}</TableCell>
                        <TableCell><Tooltip title={e.reason ?? ''}><div><MouseIcon sx={{fontSize:12}} /> Hover</div></Tooltip></TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            {filtered.length === 0 ? <NoResults /> : <></>}
            <Box className={"flexElements centerElements"} sx={{p:3}}>
                <Pagination count={tablePages(rows, totalRows)} variant="outlined" defaultValue={page} onChange={(e,v) => handlePage(v)} />
            </Box>
        </TableContainer>
    </>
}

function AllComplaintsHeader({rows, filter, setRows, handleRows, setFilter, totalRows}:{rows:number,filter:string,handleRows:(currentRows:number, newRows:number, totalRows:number, setRows:(e:number) => void) => void, setRows:(e:number) => void, setFilter:(e:string) => void, totalRows:number}) {
    return <>
        <Typography variant={"h4"} sx={{mb:1}} color={"text.primary"}>All complaints</Typography>
        <div className={"flexElements spaceBetweenElements"}>
            <TextField margin={"dense"} variant={"filled"} label={"Rows"} type={"number"} value={rows} onChange={e => handleRows(rows, Number.parseInt(e.target.value), totalRows, setRows)} />
            <TextField margin={"dense"} variant={"filled"} label={"Filter"} value={filter} onChange={e => setFilter(e.target.value)} />
        </div>
    </>
}
