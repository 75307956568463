import {useCallback, useEffect, useMemo, useState} from "react"
import {getObject} from "../objectService";
import useNotifications from "./useNotifications";
import useInterval from "./useInterval";

export function useGetSingle<T>(url: string, defaultValue: T) {
    const [loading, setIsLoading] = useState(true)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [lastLoad, setLastLoad] = useState(new Date())
    const [fetchedData, setFetchedData] = useState<T>(defaultValue)
    const {error: notifyError} = useNotifications()

    const getData = useCallback(async () => {
        try {
            const {data} = await getObject<T>(url)
            setFetchedData(data)
            if (errorMsg.length > 0) setErrorMsg("")
        } catch (error: any) {
            setErrorMsg(error + "")
        } finally {
            setIsLoading(false)
            setHasLoaded(true)
            setLastLoad(new Date())
        }
    }, [url, errorMsg.length])

    useEffect(() => {
        getData()
    }, [getData, url]);

    const error = useMemo((): boolean => {
        return errorMsg.length > 0
    }, [errorMsg])


    useEffect(() => {
        if (error) notifyError(`${errorMsg}`)
    }, [error, notifyError, errorMsg])

    async function refresh() {
        setIsLoading(true)
        await getData()
        return {
            loading,
            data: fetchedData,
            refresh,
            error,
            errorMsg,
            hasLoaded: true,
            firstLoading: (loading && !hasLoaded),
            lastLoad
        }
    }

    return {
        loading,
        data: fetchedData,
        refresh,
        error,
        errorMsg,
        hasLoaded,
        firstLoading: (loading && !hasLoaded),
        lastLoad
    }
}


export default function useGetCollection<T>(url: string) {
    return useGetSingle<Array<T>>(url, [])
}

export function usePolling(method: () => void, error: boolean, standardRate: number, delayIfError: number = 10) {
    const refreshRate = useMemo(() => {
        return error ? standardRate + delayIfError : standardRate
    }, [error, standardRate, delayIfError])
    useInterval(method, refreshRate)

    return {refreshRate}
}
