import {User} from "./User";

export default interface File {
    id: string
    name: string
    category: string|null
    size: number
    uploaded: Date
    uploader: User|null
}

export function filterFile(file:File, filter:string) {
    filter = filter.toLowerCase()
    if(file.name.toLowerCase().includes(filter)) return true
    if(file.name.toLowerCase().replaceAll("-", " ").includes(filter)) return true
    if(file.name.toLowerCase().replaceAll("_", " ").includes(filter)) return true
    if(file.name.toLowerCase().replaceAll("+", " ").includes(filter)) return true
    if(file.category !== null) {
        if(file.category.toLowerCase().includes(filter)) return true
        if(file.category.toLowerCase().replaceAll("-", " ").includes(filter)) return true
        if(file.category.toLowerCase().replaceAll("_", " ").includes(filter)) return true
        if(file.category.toLowerCase().replaceAll("+", " ").includes(filter)) return true
    }
    if((`${file.uploader?.firstname} ${file.uploader?.lastname}`).toLowerCase().includes(filter)) return true
    return (`${file.uploader?.lastname} ${file.uploader?.firstname}`).toLowerCase().includes(filter);

}