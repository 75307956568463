import {useParams} from "react-router-dom";
import {defaultNewsletter} from "../../../functions/entities/Newsletter";
import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";
import {useSnackbar} from "notistack";
import Error500 from "../Errors/500";
import Form from "../../particles/Newsletter/Form";
import {useGetSingle} from "../../../functions/hooks/useGetObject";

export default function Modify() {
    const {enqueueSnackbar} = useSnackbar()
    const {letterId} = useParams<{letterId:string}>()
    const {loading, data, error, errorMsg} = useGetSingle(`/Newsletter/Get/${letterId}`, defaultNewsletter())

    if(loading) return <CenteredLoadingCircle />
    if(error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
        return <Error500 />
    }

    return <Form newsletter={data} />
}
