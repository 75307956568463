import {Tile} from "../Home/HomeTile";
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import {useMyTheme} from "../../../functions/hooks/useMyTheme";
import {grey} from "@mui/material/colors";
import {Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import RemoveIcon from '@mui/icons-material/Remove';
import {getRandom} from "../../../functions/number";

export default function LoadingWall() {
    const theme = useMyTheme()
    let counter = 0

    return <Tile xs={12} md={6} lg={5} height={"80vh"}>
        <VerticalTimeline layout={"1-column-left"} lineColor={theme.mode === "light" ? "white" : grey[400]}>
            {Array(6).fill(null).map(() => <LoadingPost key={counter++} />)}
        </VerticalTimeline>
    </Tile>
}

function LoadingPost() {
    const theme = useMyTheme()
    const color = theme.mode === "light" ? "white" : grey[400]
    let counter = 0

    return <VerticalTimelineElement
        contentStyle={{background: color}} contentArrowStyle={{borderRight: `7px solid ${color}`}}
        icon={<Skeleton variant={"circular"} sx={{width: "100%", height: "100%"}} />}
        iconStyle={{background: color, color: color}}
    >
        <section>
            <Typography variant={"h5"}><Skeleton /></Typography>
            <div className={"flexElements"} style={{color: "gray"}}>
                <RemoveIcon sx={{fontSize: 20, mt: 0.5, mr: 1}}/> <Typography
                variant={"subtitle1"}>
                <Skeleton sx={{width: getRandom(30,200)}} />
            </Typography>
            </div>
            <span style={{fontWeight: "normal", padding: 5, paddingBottom: 1}}>
                {Array(getRandom(1,4)).fill(null).map(() => <Skeleton key={counter++} sx={{width: getRandom(30,100)+"%"}} />)}
            </span>
        </section>
    </VerticalTimelineElement>
}