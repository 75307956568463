export function capitalizeFirstLetter(string:string) : string {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function capitalizeFirstLetterOrKeepCapital(string:string) : string {
    if(string === string.toUpperCase()) return string
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

const defaultPageName = "Dashboard"

export function extractPageName(path: string): string {
    const value = extractPageNameFromPath(path)
    return value.length === 0 ? defaultPageName : value
}

function extractPageNameFromPath(path: string) : string {
    const array = path.split("/")

    if (array.length === 1) return array[0].length > 0 ? array[0] : defaultPageName
    if (array.length > 1) return array[1].length > 20 ? array[0] : array[1]
    return defaultPageName
}

export function logBool(bool:boolean, log:string, enabled:boolean = false) {
    if(enabled) console.log(`Value: ${bool}, Log: ${log}`)
    return bool
}
