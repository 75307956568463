import {Button, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {Building} from "../../../../functions/entities/Building";
import useBlueprintEditor from "../../../../functions/hooks/useBlueprint";
import {deleteObject, postObject, putObject} from "../../../../functions/objectService";
import {useSnackbar} from "notistack";

export default function EntityForm() {
    const {enqueueSnackbar} = useSnackbar()
    const bpContext = useBlueprintEditor()
    const [building, setBuilding] = useState<Building|null>(bpContext.getSelectedEntity())


    useEffect(() => {
        if(building?.id !== bpContext.selectedEntityId) {
            setBuilding(bpContext.getSelectedEntity())
        }
    }, [bpContext.selectedEntityId])

    async function deleteBuilding() {
        await deleteObject<Building>(`/Blueprint/Entity/${bpContext.selectedEntityId}`)
        bpContext.updateEntities()
        enqueueSnackbar("Successfully deleted entity.", {variant: "success"})
    }

    function handleChange(attribute: string, value: string) {
        if(building != null) {
            if(Number.isInteger(value)) {
                setBuilding({...building, [attribute]: Number.parseFloat(value)})
            } else {
                setBuilding({...building, [attribute]: value})
            }
        }
    }

    async function postBuilding() {
        await postObject<Building>("/Blueprint/Building/"+bpContext.blueprint.id, {
            id: 0,
            width: bpContext.blueprint.width * 0.5,
            height: bpContext.blueprint.height * 0.5,
            xAxis: 0,
            yAxis: 0,
            blueprintId: bpContext.blueprint.id,
            name: "Undefined"
        })
        bpContext.updateEntities()
    }

    async function putBuilding() {
        try {
            if(building != null) await putObject<Building>("/Blueprint/Building", building)
            enqueueSnackbar("Successfully modified building.", {variant: "success"})
            bpContext.updateEntities()
            bpContext.setSelectedEntity(-1)
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <>
        <div>
            <Button sx={{m: 1}}
                    disabled={bpContext.selectedEntityId >= 0}
                    onClick={postBuilding}
                    variant={"contained"}>New building</Button>
            <Button sx={{m: 1}}
                    disabled={bpContext.selectedEntityId >= 0}
                    variant={"contained"}>New Street</Button>
            <Button sx={{m: 1}}
                    disabled={bpContext.selectedEntityId >= 0}
                    variant={"contained"}>New Obstacle</Button>
        </div>
        <TextField disabled={bpContext.selectedEntityId < 0} label={"Name"} value={building?.name ?? ''}
                   onChange={(e) => handleChange("name", e.target.value)} sx={{m: 1}}/>
        <TextField disabled={bpContext.selectedEntityId < 0} label={"Height (m)"} value={building?.height ?? 0}
                   type={"number"}
                   onChange={(e) => handleChange("height", e.target.value)} sx={{m: 1}}/>
        <TextField disabled={bpContext.selectedEntityId < 0} label={"Width (m)"} value={building?.width ?? 0}
                   type={"number"}
                   onChange={(e) => handleChange("width", e.target.value)} sx={{m: 1}}/>
        <Button sx={{m: 1}}
                disabled={bpContext.selectedEntityId < 0}
                onClick={putBuilding}
                variant={"contained"}>Save building</Button>
        <Button sx={{m: 1}}
                disabled={bpContext.selectedEntityId < 0}
                onClick={() => bpContext.setSelectedEntity(-1)}
                variant={"contained"}
                color={"warning"}>Deselect building</Button>
        <Button sx={{m: 1}}
                disabled={bpContext.selectedEntityId < 0}
                variant={"contained"}
                onClick={deleteBuilding}
                color={"error"}>Delete Building</Button>
    </>
}
