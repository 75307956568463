import {createContext, useContext, useState} from "react";
import {useSnackbar} from "notistack";
import CenteredLoadingCircle from "../../components/particles/loading/CenteredCircularProgress";
import {Building} from "../entities/Building";
import {useParams} from "react-router-dom";
import {Blueprint, defaultBlueprint} from "../entities/Blueprint";
import Error500 from "../../components/pages/Errors/500";
import useGetCollection, {useGetSingle} from "./useGetObject";

interface contextInterface {
    entities:Building[]
    selectedEntityId: number
    setSelectedEntity: (e:number) => void
    updateEntities: () => void
    forceRefresh: () => void
    getSelectedEntity: () => Building | null
    blueprint: Blueprint
}

const BlueprintContext = createContext<contextInterface>(
    {
        entities:[],
        selectedEntityId: 0,
        setSelectedEntity: () => {},
        updateEntities: () => {},
        forceRefresh: () => {},
        getSelectedEntity: () => {return null},
        blueprint: defaultBlueprint()
    })

export default function useBlueprintEditor() {
    return useContext(BlueprintContext)
}

export function BlueprintEditorProvider({children} : {children:JSX.Element[]|JSX.Element}) {
    const { blueprintId } = useParams<{ id:string, blueprintId:string }>();
    const {enqueueSnackbar} = useSnackbar()
    const [selectedBuilding, setSelectedBuilding] = useState(-1)
    const [forceRefresh, setForceRefresh] = useState(false)
    const {loading, data, error, refresh, errorMsg} = useGetCollection<Building>("/Blueprint/Entities/"+blueprintId)
    const {loading: bpLoading, data : bpData, error: bpError, errorMsg: bpErrorMsg} = useGetSingle<Blueprint>(`/Blueprint/Get/${blueprintId}`, defaultBlueprint())

    if(loading || bpLoading) return <CenteredLoadingCircle />
    if(error) enqueueSnackbar(errorMsg, {variant: "error"})
    if(bpError) enqueueSnackbar(bpErrorMsg, {variant: "error"})

    if(error || bpError) return <Error500 />

    function getSelectedEntity() {
        const index = data.findIndex(e => selectedBuilding === e.id)
        if(index < 0) return null
        return data[index]
    }

    return <BlueprintContext.Provider value={{
        entities: data,
        setSelectedEntity: setSelectedBuilding,
        selectedEntityId: selectedBuilding,
        updateEntities: updateBuildings,
        forceRefresh: () => setForceRefresh(!forceRefresh),
        blueprint: bpData,
        getSelectedEntity: getSelectedEntity
    }}>
        {children}
    </BlueprintContext.Provider>

    async function updateBuildings() {
        await refresh()
        enqueueSnackbar("Updated buildings", {variant: "success"})
    }
}
