import {postObjectWithResponse} from "./objectService";
import {putAuthToken} from "./authToken";

export async function authenticate(email: string, password: string): Promise<AuthenticateStatus> {
    try {
        const value = await postObjectWithResponse<any, string>("/Authentication/Login", {
            email: email,
            password: password,
            rememberMe: true
        })
        const token = value.data
        if (token != null && value.data.length > 2 && value.status === 200) {
            return {success: true, code: value.status, message: value.statusText, token: token}
        } else {
            return {success: false, code: value.status, message: value.statusText, token: token}
        }
    } catch (e) {
        console.log(e)
        if((e+"").includes("401")) {
            return {success: false, code: 401, message: "Please verify your login credentials.", token: ''}
        } else {
            return {success: false, code: 500, message: (e+"").replaceAll("Axios", ""), token: ''}
        }
    }
}

interface AuthenticateStatus {
    success : boolean
    code : number
    message: string | null
    token: string
}

export async function logout() {
    putAuthToken(undefined)
}
