import React, {useEffect, useState} from "react";
import useNotifications from "../../../functions/hooks/useNotifications";
import {FileUploadStatus, postFileObject} from "../../../functions/objectService";
import {Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import bytesToReadable from "../../../functions/bytesToReadable";
import useCloud from "../../../functions/hooks/useCloud";
import TextField from "@mui/material/TextField";

export default function UploadFileModal({open, handleClose, category,allowEditing=false}:{category:string|null, open:boolean, handleClose:()=>void, allowEditing?:boolean}) {
    const [files, setFiles] = useState<File[]>([])
    const [uploading, setUploading] = useState(-1)
    const [newCategory, setCategory] = useState(category ?? "")
    const {warning, error, success} = useNotifications()
    const {refresh} = useCloud()

    useEffect(() => {
        if(newCategory.includes("/")) setCategory(newCategory.replaceAll("/", ""))
        if(newCategory.includes("\\")) setCategory(newCategory.replaceAll("\\", ""))
    }, [newCategory])

    async function handleSubmit() {
        try {
            if(files.length === 0) return;
            warning("Please keep this page open until the upload is done!")
            for (const file of files) {
                const index = files.indexOf(file);
                setUploading(index)
                const response = await postFileObject(category === null || newCategory.length === 0 ? "/Cloud2/Upload" : `/Cloud2/Upload/${newCategory}`, "", file)
                if(response.data === FileUploadStatus.Failed) {
                    error(`Failed to upload ${file.name}, exiting.`)
                    break
                } else if(response.data === FileUploadStatus.Skipped) {
                    warning(`${file.name} may have been skipped.`)
                } else {
                    success(`${file.name} has been uploaded.`)
                }
            }
            handleClose()
            await refresh()
        } catch (e:any) {
            error(`${e}`)
        } finally {
            setUploading(-1)
        }
    }

    let filesToList : { file: File, index: number }[] = []
    for(let i = 0; i < (files?.length ?? 0); i++) {
        filesToList.push({
            file: files[i] ?? null,
            index: i
        })
    }

    function fileListToArray(list:FileList|null) {
        if(list === null) return [];
        let tempList : File[] = []
        for(let i = 0; i < (list?.length ?? 0); i++) {
            tempList.push(list[i])
        }
        return tempList
    }

    function handleRemoveFile(index:number) {
        setFiles(files.filter((a,b) => b !== index))
    }

    return <Dialog open={open} onClose={() => {
        if(uploading === -1) handleClose()
        else warning("Keep this window open until the files are uploaded!")
    }}>
        <DialogTitle>
            Upload file to {allowEditing ? newCategory : category ?? "ROOT"}
        </DialogTitle>
        <DialogContent>
            <TextField placeholder={"Insert category title"} margin={"dense"} size={"small"} sx={{mb:1, display: allowEditing ? "block" : "none", width: "100%"}} value={newCategory} onChange={e => setCategory(e.target.value)} />
            {files !== null && filesToList.map(e => <FileState file={e.file} handleRemove={() => handleRemoveFile(e.index)} uploaded={false} uploading={false} />)}
            <input
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={e => setFiles(fileListToArray(e.target.files))}
            />
            <label htmlFor="raised-button-file">
                <Button component="span" sx={{width: "100%"}}>
                    Select file
                </Button>
            </label>
        </DialogContent>
        <DialogActions>
            <Button color={"warning"} onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Save file</Button>
        </DialogActions>
    </Dialog>
}

function FileState({file, uploaded, uploading, handleRemove}:{handleRemove:()=> void,file:File|null, uploaded:boolean, uploading:boolean}) {
    if(file === null) return <></>
    return <Alert sx={{my:0.5}} severity={uploaded ? "success" : uploading ? "warning" : "info"} action={<IconButton onClick={handleRemove}>
        <CloseIcon />
    </IconButton>}>
        <AlertTitle>{file.name}</AlertTitle>
        Size: {bytesToReadable(file.size)}
    </Alert>
}