import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useState} from "react";
import File from "../../../functions/entities/File";

export default function FileDetailModal({file, open, handleClose}:{file:File, open:boolean, handleClose:()=>void}) {
    const [clickedDelete, setClickedDelete] = useState(false)

    function handleDeleteClick() {
        if(!clickedDelete) setClickedDelete(true)
        else {

        }
    }

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
            {file.name}
        </DialogTitle>
        <DialogContent>
            Details go here
        </DialogContent>
        <DialogActions className={"flexElements spaceBetweenElements"}>
            <div>
                <Button color={"error"} onClick={handleDeleteClick}>{clickedDelete ? "Confirm file deletion" : "Delete file"}</Button>
                {clickedDelete && <Button onClick={() => setClickedDelete(false)} color={"warning"}>Cancel</Button>}
            </div>
            <div>
                {!clickedDelete && <>
                    <Button onClick={handleClose} color={"warning"}>Close</Button>
                </>}
            </div>
        </DialogActions>
    </Dialog>
}