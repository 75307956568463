import {Role} from "../../../functions/entities/Role";
import {useSnackbar} from "notistack";
import Error500 from "../../pages/Errors/500";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Checkbox, FormControlLabel, Skeleton} from "@mui/material";
import {deleteObject, postObjectWithResponse} from "../../../functions/objectService";
import {useState} from "react";
import useGetCollection from "../../../functions/hooks/useGetObject";

let counter = 0
export default function RolePicker({userId} : {userId:string}) {
    const {enqueueSnackbar} = useSnackbar()
    const {loading, error, data : roles, errorMsg} = useGetCollection<Role>("/Account/Roles")
    const {loading : userRolesLoading, error : userRolesError, data : userRoles, errorMsg : userRolesErrorMsg, refresh} = useGetCollection<string>(`/Account/Roles/${userId}`)
    const [handlingActivity, setHandlingActivity] = useState(false)

    if(error) {
        enqueueSnackbar(`${errorMsg}`, {variant: "error"})
        return <Error500 />
    }

    if(userRolesError) {
        enqueueSnackbar(`${userRolesErrorMsg}`, {variant: "error"})
        return <Error500 />
    }

    async function toggleRole(role: string) {
        try {
            setHandlingActivity(true)
            if (hasRole(role)) {
                await deleteObject(`/Account/RemoveRole/${userId}/${role}`)
            } else {
                await postObjectWithResponse<{entity:string}, any>(`/Account/AddRole/${userId}`, {entity: role})
            }
            await refresh()
            enqueueSnackbar(`Successfully updated roles.`, {variant: "success"})
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        } finally {
            setHandlingActivity(false)
        }
    }

    function hasRole(role:string) {
        return userRoles.includes(role)
    }

    if(loading || userRolesLoading || handlingActivity) return <LoadingRolePicker />
    return <Box component={Paper} sx={{p: 3, width: "fit-content"}}>
        <Typography variant={"h5"}>Select roles</Typography>
        <Box sx={{p: 1}}>
            {roles.map(e => <RoleCard key={++counter} role={e} onClick={toggleRole} active={hasRole(e.id)} />)}
        </Box>
    </Box>
}

function RoleCard({role, onClick, active} : {role:Role, onClick: (e:string) => void, active: boolean}) {
    return <Box>
        <FormControlLabel
            onClick={() => onClick(role.id)}
            control={<Checkbox defaultChecked={active} />}
            label={role.normalizedName} />
    </Box>
}

function LoadingRolePicker() {
    return <Box component={Paper} sx={{p: 3, width: "fit-content"}}>
        <Typography variant={"h5"}>Select roles</Typography>
        <Box sx={{p: 1}}>
            {Array(7).fill(null).map(() => <Skeleton sx={{width: "100%", p:1, minWidth: 185}} animation={"wave"} key={++counter} />)}
        </Box>
    </Box>
}
