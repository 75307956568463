export interface CloudFile {
    type: CloudFileType;
    subType: number;
    fullName: string;
    name: string;
    linkTarget?: any;
    linkCount: number;
    linkObject?: any;
    modified: Date;
    created: Date;
    rawModified: Date;
    rawCreated: Date;
    size: number;
    specialPermissions: number;
    ownerPermissions: number;
    groupPermissions: number;
    othersPermissions: number;
    rawPermissions: string;
    chmod: number;
    rawGroup: string;
    rawOwner: string;
    input: string;
}

export enum CloudFileType {
    File = 0,
    Directory = 1
}