import Typography from "@mui/material/Typography";
import noresults from "../../../media/img/noresults.png";

export default function NoResults({message = "No results!", fontSize = 50}: { message?: string, fontSize?: number }) {
    return <>
        <div className={"flexElements verticalAlignCenter centerElements"}>
            <div style={{textAlign: "center"}}>
                <Typography color={"text.primary"} variant={"h3"} sx={{p: 5}} fontSize={fontSize}>{message}</Typography>
                <img src={noresults} alt={"no results"} style={{width: 300, opacity: 0.7}}/>
            </div>
        </div>
    </>
}
