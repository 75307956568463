import {Assignment, AssignmentStatus, defaultAssignment} from "../../../functions/entities/Assignment";
import {useState} from "react";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import {Button, TextField} from "@mui/material";
import {postObject} from "../../../functions/objectService";
import {useSnackbar} from "notistack";
import {useBoard} from "../../../functions/hooks/useBoard";

export default function NewAssignment({status}:{status:AssignmentStatus}) {
    const [display, setDisplay] = useState(false)

    if(!display) return <PlusAssignment />

    function PlusAssignment() {
        return <Box onClick={() => setDisplay(true)} className={"addAssignmentDiv"}>
            <AddIcon sx={{color: "text.default"}} />
        </Box>
    }

    return <AssignmentAddForm status={status} hideForm={() => setDisplay(false)} />
}

function AssignmentAddForm({status, hideForm} : {status:AssignmentStatus, hideForm:() => void}) {
    const {enqueueSnackbar} = useSnackbar()
    const board = useBoard()
    const [assignment, setAssignment] = useState<Assignment>({...defaultAssignment(), "status": status})

    function handleChange(attribute:string, value:string) {
        setAssignment({...assignment, [attribute]: value})
    }

    async function handleSubmit() {
        try {
            await postObject<Assignment>("/Assignment/Add", assignment)
            enqueueSnackbar(`Successfully added ${assignment.subject}`, {variant: "success"})
            hideForm()
            board.refresh()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Box sx={{p:1}}>
        <TextField margin={"dense"} fullWidth={true} label={"Subject"} value={assignment.subject} onChange={e => handleChange("subject", e.target.value)} />
        <TextField margin={"dense"} fullWidth={true} label={"Description"} multiline value={assignment.description} onChange={e => handleChange("description", e.target.value)} />
        <div className={"flexElements centerElements"}>
            <Button variant={"contained"} sx={{mx: 1}} onClick={handleSubmit}>Add</Button>
            <Button variant={"contained"} color={"error"} sx={{mx: 1}} onClick={hideForm}>Cancel</Button>
        </div>
    </Box>
}