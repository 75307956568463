import React, {createContext, useContext, useEffect} from "react";
import useNotifications from "./useNotifications";
import useGetCollection, {usePolling} from "./useGetObject";
import CenteredLoadingCircle from "../../components/particles/loading/CenteredCircularProgress";
import File from "../entities/File";

const Cloud = createContext<{
    files: File[],
    loading: boolean,
    refresh: () => void
}>({
    files: [],
    loading: true,
    refresh: () => {}
})

export default function useCloud() {
    return useContext(Cloud)
}

export function CloudProvider(props:{children: React.ReactNode}) {
    const {data : files, refresh, loading, hasLoaded, error, errorMsg} = useGetCollection<File>("/cloud2/get")
    usePolling(refresh, error, 20)
    const {error : sendError} = useNotifications()

    useEffect(() => {
        if(error) sendError(errorMsg)
    }, [error, sendError, errorMsg])

    if(loading && !hasLoaded) return <CenteredLoadingCircle />

    return <Cloud.Provider value={{
        files,
        loading,
        refresh: refresh
    }}>
        {props.children}
    </Cloud.Provider>
}