export interface Address {
    id: number
    street: string
    streetNr: string
    bus?: string
    city: string
    postalCode: string
    country: string
}

export function addressToString(address : Address | null | undefined) {
    if(address === null) return ""
    if(address === undefined) return ""
    const bus = address.bus == null ? "/"+address.bus : "";
    return `${address.street} ${address.streetNr}${bus}, ${address.city} ${address.postalCode}, ${address.country}`
}

export function addressToShortString(address : Address | undefined | null) {
    if(address === null) return ""
    if(address === undefined) return ""
    return `${address?.city}, ${address?.country}`
}