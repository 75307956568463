import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import SunEditor from "suneditor-react";
import {options} from "../../../functions/suneditor/options";
import Newsletter from "../../../functions/entities/Newsletter";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {postObject, putObject} from "../../../functions/objectService";
import {useNavigate} from "react-router-dom";

export default function Form({newsletter} : {newsletter:Newsletter}) {
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()
    const [letter, setLetter] = useState(newsletter)

    function handleChange(attribute:string, value:string) {
        setLetter({...letter, [attribute]: value})
    }

    async function handleSubmit() {
        if(isCreatorMode(letter)) await handlePost()
        else await handlePut()
    }

    async function handlePost() {
        try {
            await postObject<Newsletter>("/Newsletter/Add", letter)
            enqueueSnackbar('Successfully created newsletter', {variant: "success"})
            navigate("/Newsletters")
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    async function handlePut() {
        try {
            await putObject<Newsletter>("/Newsletter/Modify", letter)
            enqueueSnackbar('Successfully modified newsletter', {variant: "success"})
            navigate(`/Newsletters/${letter.id}`)
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <>
        <Typography variant={"h3"} color="text.primary">{isCreatorMode(newsletter) ? 'Create' : 'Modify'} Newsletter</Typography>
        <TextField label={"Subject"} fullWidth={true} margin={"dense"} variant={"filled"} value={letter.subject} onChange={(e) => handleChange('subject', e.target.value)} />
        {isCreatorMode(letter) ? <></>
        : <SunEditor height={"55vh"} placeholder={"Start typing..."} defaultValue={letter.content} onChange={e => handleChange('content', e ?? '')} setAllPlugins={true} setOptions={options} />
        }
        <div className={"flexElements centerElements"}>
            <Button variant={"contained"} sx={{mt:1}} onClick={handleSubmit}>{isCreatorMode(newsletter) ? 'Create' : 'Modify'} newsletter</Button>
        </div>
    </>
}

function isCreatorMode(newsletter:Newsletter) {
    return !(newsletter.id > 0)
}
