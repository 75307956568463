import {Route, Routes} from "react-router-dom";
import React from "react";
import Pending from "../../pages/HR/Pending";
import MySessions from "../../pages/HR/MySessions";
import HrIndex from "../../pages/HR/HrIndex";
import All from "../../pages/HR/All";
import Error404 from "../../pages/Errors/404";
import UserSessions from "../../pages/HR/UserSessions";
import New from "../../pages/Complaints/New";
import ComplaintsIndex from "../../pages/Complaints/ComplaintsIndex";
import AllComplaints from "../../pages/Complaints/All";

export default function HrRouter() {
    return <Routes>
            <Route path={"/"}>
                <Route path={"/Sessions"} element={<MySessions />}/>
                <Route path={"/Sessions/All"} element={<All />}/>
                <Route path={"/Sessions/Pending"} element={<Pending />}/>
                <Route path={"/Sessions/:userId"} element={<UserSessions />}/>
                <Route path={"/Complaints/New/:userId"} element={<New/>}/>
                <Route path={"/Complaints/New"} element={<New/>}/>
                <Route path={"/Complaints/All"} element={<AllComplaints/>}/>
                <Route path={"/Complaints"} element={<ComplaintsIndex/>}/>
                <Route index element={<HrIndex/>}/>
                <Route path="/*" element={<Error404 />} />
            </Route>
        </Routes>
}
