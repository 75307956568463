import {EmployeeProvider} from "../../../functions/hooks/useEmployees";
import {Route, Routes} from "react-router-dom";
import React from "react";
import Error404 from "../../pages/Errors/404";
import Files from "../../pages/Cloud/Files";

export default function CloudRouter() {
    return <EmployeeProvider>
        <Routes>
            <Route path={"/"}>
                <Route index element={<Files/>}/>
                <Route path="*" element={<Error404/>}/>
            </Route>
        </Routes>
    </EmployeeProvider>
}
