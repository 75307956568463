export type Role = "HR" | "LocalManagement" | "UpperManagement"

export function handleComplaints() : Role[] {
    return ["HR", ... managementRoles()]
}

export function handleWorkSessions() : Role[] {
    return ["HR", ... managementRoles()]
}

export function logbookAccess() : Role[] {
    return managementRoles()
}

export function newsletterAccess() : Role[] {
    return managementRoles()
}

export function managementRoles() : Role[] {
    return ["LocalManagement", "UpperManagement"]
}
