import useEmployees, {EmployeeProvider} from "../../../functions/hooks/useEmployees";
import {SelectEmployee} from "../../particles/Employees/SelectEmployee";
import {useState} from "react";
import {Employee} from "../../../functions/entities/Employee";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {postObject} from "../../../functions/objectService";
import GoBackButton from "../../particles/Buttons/GoBackButton";

export default function New() {
    return <EmployeeProvider>
        <div className={"flexElements centerElements"}>
            <div style={{width: "fit-content"}}>
                <div className={"flexElements spaceBetweenElements"}>
                    <GoBackButton path={"/HR/Complaints"}/>
                </div>
                <div className={"flexElements centerElements"}>
                    <NewForm/>
                </div>
            </div>
        </div>
    </EmployeeProvider>
}

function NewForm() {
    const {userId} = useParams<{ userId?: string }>()
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()
    const employeeContext = useEmployees()
    const [accused, setAccused] = useState<null | Employee>(findEmployee(employeeContext.employees, userId))
    const [reason, setReason] = useState('')

    async function handleSubmit() {
        try {
            if (accused === null) throw "An employee must be selected."
            if (reason.length < 50) throw "You must write at least 50 characters."
            await postObject<{ accusedId: string, reason: string }>("/Complaint/Add", {
                accusedId: accused?.id,
                reason: reason
            })
            enqueueSnackbar("Successfully created complaint.", {variant: "success"})
            navigate("/HR/Complaints")
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Box component={Paper} sx={{p: 3, width: "fit-content"}}>
        <div>
            <Typography variant={"h4"} sx={{mb: 2}}>Complain employee</Typography>
            <SelectEmployee selected={accused} setSelected={setAccused} defaultValue={null}
                            list={employeeContext.employees}/>
            <TextField
                fullWidth
                label={"Reason"}
                margin={"dense"}
                multiline
                minRows={5}
                error={reason.length < 50}
                helperText={reason.length < 50 ? `The reason must be at least 50 characters long. (counted: ${reason.length})` : null}
                value={reason}
                onChange={(e) => setReason(e.target.value)}/>
            <div className={"flexElements centerElements"}>
                <Button sx={{mt: 1}} variant={"contained"} onClick={handleSubmit}>Create complaint</Button>
            </div>
        </div>
    </Box>
}

function findEmployee(list: Employee[], userId: string | undefined): Employee | null {
    if (list.length === 0) return null
    if (userId === undefined) return null
    const index = list.findIndex(e => e.id === userId)
    if (index < 0) return null
    return list[index]
}
