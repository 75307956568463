import {useJwt} from "react-jwt";
import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {User} from "../entities/User";
import {logBool} from "../string";
import Error400 from "../../components/pages/Errors/400";
import {getAuthToken, putAuthToken} from "../authToken";
import {useSnackbar} from "notistack";
import useInterval from "./useInterval";

function DefaultAuthorized(props: { children: JSX.Element, roles?: string[], anyRole?: boolean, displayErrorMsg?: boolean }) {
    return <CheckAccessAuthorized token={""} roles={props.roles} anyRole={props.anyRole}
                                  children={props.children} displayErrorMsg={props.displayErrorMsg}/>
}

function DefaultNotAuthorized(props: { children: JSX.Element, roles?: string[], anyRole?: boolean }) {
    return <CheckAccessNotAuthorized token={""} roles={props.roles} anyRole={props.anyRole}
                                     children={props.children}/>
}

interface AuthInterface {
    Authorized: (props: { children: JSX.Element, roles?: string[] | undefined, anyRole?: boolean, displayErrorMsg?: boolean }) => JSX.Element
    NotAuthorized: (props: { children: JSX.Element, roles?: string[] | undefined, anyRole?: boolean }) => JSX.Element
    Token: string | undefined
    setToken: (e: string | undefined) => void
    user: User | null
    isAuthorized: (roles?: string[], anyRole?: boolean) => boolean
}


export let AuthContext = createContext<AuthInterface>({
    Authorized: DefaultAuthorized,
    NotAuthorized: DefaultNotAuthorized,
    Token: "",
    setToken: () => {
    },
    user: null,
    isAuthorized: defaultIsAuthorized
})

export default function useAuth() {
    return useContext(AuthContext)
}

export function Auth({children}: { children: JSX.Element }) {
    const {enqueueSnackbar} = useSnackbar()
    const [token, setToken] = useState<string | undefined>(getAuthToken())
    const {decodedToken, isExpired} = useJwt<User>(token ?? '')
    const checkToken = useCallback(() => {
        putAuthToken(token)
        //console.log(`Checking token validity, has expired: ${isExpired}`)
        if (isExpired || decodedToken === null || token === "" || token === undefined) {
            //console.log(`RESETTING TOKEN 1`)
            //putAuthToken(undefined)
            return false
        }
        //manual expired
        if (decodedToken != null && decodedToken.exp < (new Date().getTime() / 1000)) {
            console.log(`Should be expired by now.`)
            putAuthToken(undefined)
            setToken(undefined)
            enqueueSnackbar('Your session has expired.', {variant: "warning"})
            return false
        }
        return true
    }, [decodedToken, enqueueSnackbar, isExpired, token])
    useInterval(checkToken, 11)

    useEffect(() => {
        checkToken()
    }, [token, isExpired, checkToken])

    function isAuthorized(roles: string[] = []): boolean {
        if (token == null || token === "") return false
        if (decodedToken == null || isExpired) return false //token has expired or is invalid
        if (roles != null && roles.length > 0) {
            if (hasAnyRole(decodedToken.role, roles)) return true //Must have one of many roles
            return false //Roles are defined, but has no role access
        }
        return true
    }

    function Authorized(props: { children: JSX.Element, roles?: string[], anyRole?: boolean, displayErrorMsg?: boolean }) {
        return <CheckAccessAuthorized token={token ?? ""} roles={props.roles} anyRole={props.anyRole}
                                      children={props.children} displayErrorMsg={props.displayErrorMsg}/>
    }

    function NotAuthorized(props: { children: JSX.Element, roles?: string[], anyRole?: boolean }) {
        if (decodedToken == null || token === "" || isExpired) return props.children //token is invalid or has expired, so is not logged in
        return <CheckAccessNotAuthorized token={token ?? ""} roles={props.roles} anyRole={props.anyRole}
                                         children={props.children}/>
    }

    return <AuthContext.Provider value={{
        Authorized: Authorized,
        NotAuthorized: NotAuthorized,
        Token: token,
        setToken: (e: string | undefined) => setToken(e),
        user: decodedToken,
        isAuthorized: isAuthorized
    }}>
        {children}
    </AuthContext.Provider>
}

function defaultIsAuthorized(): boolean {
    return false
}

function hasAnyRole(userRoles: string[] | string, mustHaveRoles: string[] | string): boolean {
    if (!Array.isArray(userRoles) && Array.isArray(mustHaveRoles)) {
        if (mustHaveRoles.filter(r => (r + "").toLowerCase() === (userRoles + "").toLowerCase()).length > 0) return logBool(true, `hasAnyRole string+array, passed.`)
    } else {
        if (Array.isArray(mustHaveRoles) && Array.isArray(userRoles)) {
            if (mustHaveRoles.filter(mustHaveRole => {
                return userRoles.filter(ur => ur.toLowerCase() === mustHaveRole).length > 0
            })) return logBool(true, `hasAnyRole array+string, passed.`)
        } else {
            return logBool(mustHaveRoles === userRoles, `hasAnyRole string+string, passed.`)
        }
    }
    return false
}


function CheckAccessAuthorized({
                                   token,
                                   roles = [],
                                   anyRole = false,
                                   children,
                                   displayErrorMsg
                               }: { token: string, roles?: string[], anyRole?: boolean, children: JSX.Element, displayErrorMsg?: boolean }) {
    const {decodedToken, isExpired} = useJwt<User>(token)
    if (token == null || token === "") return displayErrorMsg ? <Error400/> : <></>
    if (decodedToken == null || isExpired) return displayErrorMsg ? <Error400/> : <></> //token has expired or is invalid
    if (roles != null && roles.length > 0) {
        if (anyRole && hasAnyRole(decodedToken.role, roles)) return children //Must have one of many roles
        return displayErrorMsg ? <Error400/> : <></> //Roles are defined, but has no role access
    }

    return children
}


function CheckAccessNotAuthorized({
                                      token,
                                      roles = [],
                                      anyRole = false,
                                      children
                                  }: { token: string, roles?: string[], anyRole?: boolean, children: JSX.Element }) {
    const {decodedToken, isExpired} = useJwt<User>(token)
    if (token == null || token === "") return children
    if (decodedToken == null || isExpired) return children //token has expired or is invalid
    if (roles != null && roles.length > 0) {
        if (anyRole && hasAnyRole(decodedToken.role, roles)) return <></> //Must have one of many roles
        return children //Roles are defined, but has no role access
    }

    return <></>
}
