import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {Skeleton} from "@mui/material";
import {postObject} from "../../../functions/objectService";
import {useGetSingle} from "../../../functions/hooks/useGetObject";
import {useState} from "react";
import RefreshButton from "../Buttons/RefreshButton";
import LoadingButton from "../Buttons/LoadingButton";
import useInterval from "../../../functions/hooks/useInterval";

export default function QuickSession() {
    const [refreshNowClicked, setRefreshNowClicked] = useState(false)
    const {
        data: activeSession,
        error,
        refresh,
        firstLoading
    } = useGetSingle<boolean>("/HumanResources/HasSession", false)
    useInterval(refresh, error ? 180 : 90)

    if (firstLoading) {
        return <LoadingQuickSession/>
    }

    async function manualRefresh() {
        setRefreshNowClicked(true)
        await refresh()
        setRefreshNowClicked(false)
    }

    if (error) {
        return <>
            <p>Failed to get work session status..</p>
            <div>
                {refreshNowClicked ? <LoadingButton/> : <RefreshButton text={"Refresh now"} refresh={manualRefresh}/>}
            </div>
        </>
    }

    async function toggleSession() {
        if (activeSession) {
            await postObject("/HumanResources/EndSession", {})
        } else {
            await postObject("/HumanResources/StartSession", {})
        }
        await refresh()
    }

    return <Box component={Paper} sx={{p: 3, width: "fit-content", minWidth: "250px"}}
                className={"flexElements centerElements"}>
        <div>
            <div className={"flexElements centerElements"}>
                <IconButton onClick={toggleSession} sx={{p: 2}}>
                    {!activeSession && <>
                        <PlayArrowIcon sx={{color: "lightgreen"}} fontSize="large"/>
                    </>}
                    {activeSession && <>
                        <StopIcon sx={{color: "red"}} fontSize="large"/>
                    </>}
                </IconButton>
            </div>

            <Typography variant={"h5"}>{!activeSession ? 'Start' : 'Stop'} work session</Typography>
        </div>
    </Box>
}

export function LoadingQuickSession() {
    return <Box component={Paper} sx={{p: 3, width: "fit-content", minWidth: "250px"}}
                className={"flexElements centerElements"}>
        <div>
            <HourglassTopIcon fontSize="large" sx={{p: 3}}/>
            <Typography variant={"h5"}><Skeleton/></Typography>
        </div>
    </Box>
}
