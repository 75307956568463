import {Tab, Tabs} from "@mui/material";
import React, {useState} from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Profile from "../particles/Account/Profile";
import TwoFactorAuth from "../particles/Account/TwoFactorAuth";
import ModifyPassword from "../particles/Account/ModifyPassword";

export default function Account() {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return <Paper>
        <Tabs value={tabValue} onChange={handleChange} centered variant="fullWidth">
            <Tab label="Profile"/>
            <Tab label="2FA"/>
            <Tab label="Password"/>
        </Tabs>
        <Box sx={{p: 5, pt: 3}}>
            <GetTabSection value={tabValue} />
        </Box>
    </Paper>
}

function GetTabSection({value} : {value:Number}) {
    if(value === 2) return <ModifyPassword />
    if(value === 1) return <TwoFactorAuth />
    return <Profile />
}
