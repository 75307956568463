import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Factory, filterFactory} from "../../../functions/entities/Factory";
import {addressToShortString} from "../../../functions/entities/Address";

let counter = 0
export const factoryTableHeader = ["Name", "Address"]
export default function FactoriesTable({factories, filter = ""} : {factories : Factory[],filter?:string}) {
    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {factoryTableHeader.map((title) => <TableCell sx={{textAlign: "center"}} key={++counter}>{title}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {factories.filter(e => filterFactory(filter, e)).map((e) => {
                    return <FactoryRow key={++counter} factory={e} />
                })}
            </TableBody>
        </Table>
    </TableContainer>
}
function FactoryRow(entity : { factory: Factory }) {
    const navigate = useNavigate()
    const [mouseEntered, setMouseEntered] = useState(false)

    function handleClick() {
        navigate("/Factories/"+entity.factory.id)
    }

    return <TableRow className={"clickableRow"} sx={mouseEntered ? {cursor: "pointer"} : {}} onClick={handleClick} onMouseEnter={() => setMouseEntered(true)} onMouseLeave={() => setMouseEntered(false)}>
        <TableCell align={"center"}>{entity.factory.name}</TableCell>
        <TableCell align={"center"}>{entity.factory.address !== undefined ? addressToShortString(entity.factory.address) : ''}</TableCell>
    </TableRow>
}
