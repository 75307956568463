import IconButton from "@mui/material/IconButton";
import {Avatar, ListItemIcon, Menu, MenuItem, Tooltip} from "@mui/material";
import useAuth from "../../../functions/hooks/useAuth";
import {useState} from "react";
import Divider from "@mui/material/Divider";
import {Logout, Settings} from "@mui/icons-material";
import {logout} from "../../../functions/authenticationService";
import {useNavigate} from "react-router-dom";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

export default function ProfileButton() {
    const auth = useAuth()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    let name = '??'
    try {
        name = `${auth.user?.firstname[0]}${auth.user?.lastname[0]}`
    } catch {

    }

    return <>
        <Tooltip title="Your account">
            <IconButton color="inherit" onClick={handleClick}>
                <Avatar
                    src={auth.user?.profilePicture}
                    sx={{bgcolor: "primary.light"}}>{name}</Avatar>
            </IconButton>
        </Tooltip>
        <ProfileMenu open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </>
}

export function ProfileMenu({
                                open,
                                anchorEl,
                                handleClose
                            }: { open: boolean, anchorEl: (null | HTMLElement), handleClose: () => void }) {
    const auth = useAuth()
    const navigate = useNavigate()

    async function handleLogout() {
        await logout()
        auth.setToken("")
    }

    return <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
    >
        <MenuItem onClick={() => navigate("/Account")}>
            <ListItemIcon>
                <Avatar sx={{height: 25, width: 25}} />
            </ListItemIcon>
            Account
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => navigate("/HR/Sessions")}>
            <ListItemIcon>
                <WorkHistoryIcon fontSize="small"/>
            </ListItemIcon>
            Work Sessions
        </MenuItem>
        <MenuItem onClick={() => navigate("/Settings")}>
            <ListItemIcon>
                <Settings fontSize="small"/>
            </ListItemIcon>
            Settings
        </MenuItem>
        <Divider/>
        <MenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
                <Logout fontSize="small"/>
            </ListItemIcon>
            Logout
        </MenuItem>
    </Menu>
}
