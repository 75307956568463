import {Assignment} from "../../../functions/entities/Assignment";
import {Button, Chip, TextField, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {useBoard} from "../../../functions/hooks/useBoard";
import Divider from "@mui/material/Divider";
import {EmployeeProvider} from "../../../functions/hooks/useEmployees";
import CircularSkeleton from "../loading/CircularSkeleton";
import Assignee from "../../../functions/entities/Assignee";
import {chipSelectedColor, chipSelectedVariant} from "../../../functions/chipOptions";
import {AssignmentAssignee} from "./AssigneeForm";
import {EmployeeAvatar} from "../Employees/EmployeeAvatar";
import {putObject} from "../../../functions/objectService";
import useGetCollection from "../../../functions/hooks/useGetObject";

let keyCounter = 0
export function ModifyAssignmentForm({item, close}: { item: Assignment, close: () => void }) {
    const board = useBoard()
    const {loading, data : assignees, error, refresh} = useGetCollection<Assignee>(`/Assignment/Assignees/${item.id}`)
    const [assigneeModifier, setAssigneeModifier] = useState(false)
    const [modifiedItem, setItem] = useState(item)
    const {enqueueSnackbar} = useSnackbar()
    const smallScreen = useMediaQuery("(min-width: 400px)")

    function handleChange(attribute: string, value: string) {
        setItem({...modifiedItem, [attribute]: value})
    }

    function handleNumericalChange(attribute: string, value: number) {
        setItem({...modifiedItem, [attribute]: value})
    }

    async function handleSubmit() {
        try {
            if(modifiedItem === item) return;
            const entity = await putObject<Assignment>("/Assignment/Modify", modifiedItem)
            enqueueSnackbar(`Successfully modified ${entity.data.subject}.`, {variant: "success"})
            close()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        } finally {
            await board.refresh()
        }
    }

    async function employeeRefresh() {
        await refresh()
        setAssigneeModifier(false)
    }

    return <Box sx={{minWidth: "40vw"}}>
        <Toolbar sx={{px: [1]}}/>
        <div className={"flexElements spaceBetweenElements"}>
            <div style={smallScreen ? {display: "none"} : {}}>
                <IconButton onClick={close} sx={{position: "relative", m: 1}}>
                    <CloseIcon/>
                </IconButton>
            </div>
            <Box sx={{m: 3, mt: 2}}>
                <Typography variant={"h5"} sx={{mb: 1}}>Modify assignment</Typography>
                <TextField
                    fullWidth={true}
                    margin={"dense"}
                    label={"Title"}
                    value={modifiedItem.subject}
                    onChange={e => handleChange("subject", e.target.value)}
                    error={modifiedItem.subject.length === 0}
                />

                <Box sx={{my: 2}}>
                    <Typography variant={"body2"} sx={{color: "gray", fontSize: 12, ml: 1.5}}>Status</Typography>
                    <Chip onClick={() => handleNumericalChange("status", 0)} sx={{mr: 1}} label="Backlog"
                          variant={chipSelectedVariant(0, modifiedItem.status)}
                          color={chipSelectedColor(0, modifiedItem.status)}/>
                    <Chip onClick={() => handleNumericalChange("status", 2)} sx={{mr: 1}} label="Ongoing"
                          variant={chipSelectedVariant(2, modifiedItem.status)}
                          color={chipSelectedColor(2, modifiedItem.status)}/>
                    <Chip onClick={() => handleNumericalChange("status", 1)} sx={{mr: 1}} label="Completed"
                          variant={chipSelectedVariant(1, modifiedItem.status)}
                          color={chipSelectedColor(1, modifiedItem.status)}/>
                    <Chip onClick={() => handleNumericalChange("status", -1)} sx={{mr: 1}} label="Cancelled"
                          variant={chipSelectedVariant(-1, modifiedItem.status)}
                          color={chipSelectedColor(-1, modifiedItem.status)}/>
                </Box>

                <TextField
                    fullWidth={true}
                    margin={"dense"}
                    multiline={true}
                    label={"Description"}
                    value={modifiedItem.description}
                    minRows={3}
                    onChange={e => handleChange("description", e.target.value)}
                />

                <Button variant={"contained"} sx={{mt: 2}} onClick={handleSubmit}>Save assignment</Button>

                <Divider sx={{my: 3}}/>

                <Typography variant={"h5"}>Assignees</Typography>
                {assigneeModifier
                    ? <EmployeeProvider><AssignmentAssignee refresh={employeeRefresh} defaultEmployees={loading ? [] : assignees} assignment={item.id}/></EmployeeProvider>
                    : <>
                        <div>
                            {loading || error
                                ? <CircularSkeleton diameter={40} count={5} />
                                : <div className={"flexElements"}>
                                    {assignees.map(e => <EmployeeAvatar key={keyCounter++} employee={e.user} />)}
                                </div>
                            }
                        </div>
                        <Button variant={"contained"} onClick={() => setAssigneeModifier(true)}>Modify</Button>
                    </>
                }
            </Box>
        </div>
    </Box>
}
