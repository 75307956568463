import {Route, Routes} from "react-router-dom";
import React from "react";
import Error404 from "../../pages/Errors/404";
import Logbook from "../../pages/Logbook/Logbook";

export default function LogbookRouter() {
    return <Routes>
        <Route path={"/"}>
            <Route path={"/:userId"} element={<Logbook />}/>
            <Route index element={<Logbook/>}/>
            <Route path="/*" element={<Error404 />} />
        </Route>
    </Routes>
}
