import {Blueprint} from "../../../../functions/entities/Blueprint";
import {useNavigate} from "react-router-dom";
import {Card, CardActionArea, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography"

export function BlueprintCard({factoryId, blueprint}: { factoryId: string | number, blueprint: Blueprint }) {
    const navigate = useNavigate()

    return <Card sx={{maxWidth: 345, mr: 1, mb: 1}}>
        <CardActionArea onClick={() => navigate(`/Factories/${factoryId}/Blueprint/${blueprint.id}`)}>
            <CardContent sx={{m: 2, mx: 4}}>
                <Typography gutterBottom variant="h5" component="div">
                    {blueprint.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Size: {blueprint.height * blueprint.width}m²
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}
