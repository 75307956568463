import useBlueprintEditor from "../../../../functions/hooks/useBlueprint";
import {Building} from "../../../../functions/entities/Building";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

let counter = 0
export default function EntityList() {
    const bpContext = useBlueprintEditor()

    return <>
        {bpContext.entities.map(e => <EntityCard key={++counter} building={e} />)}
    </>
}

function EntityCard({building}:{building:Building}) {
    const bpContext = useBlueprintEditor()

    const activeBg = {
        background: building.id === bpContext.selectedEntityId ? "orange" : ""
    }

    return <Paper sx={{mb:1, p:1, cursor: "pointer", ...activeBg}} onClick={() => bpContext.setSelectedEntity(building.id)}>
        <Typography variant={"h6"}>{building.name} #{building.id}</Typography>
    </Paper>
}
