import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";

export default function RedirectButton({text, path, icon, color = "primary"}: { text: string, path: string, icon: JSX.Element, color?:"primary"|"warning"|"error" }) {
    const navigate = useNavigate()

    return <Button startIcon={icon} color={color} onClick={() => navigate(path)} variant={"contained"} sx={{mx: 0.5, mb: 1}}>
        <Box>{text}</Box>
    </Button>
}
