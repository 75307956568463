import {Employee, employeeFullName, employeeInitials} from "../../../functions/entities/Employee";
import {Avatar} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export function EmployeeAvatar({employee, diameter = 40, my = 2}: { employee: Employee, diameter?:number, my?:number }) {
    try {
        return <Tooltip title={employeeFullName(employee)}>
            <Avatar sx={{m:0.2, my: my, width: diameter, height: diameter, fontSize: diameter/2}} key={employee.id} src={employee.profilePicture ?? ""}>{employeeInitials(employee)}</Avatar>
        </Tooltip>
    } catch {
        return <Tooltip title={employeeFullName(employee)}>
            <Avatar sx={{m:0.2, my: my, width: diameter, height: diameter, fontSize: diameter/2}}>{employeeInitials(employee)}</Avatar>
        </Tooltip>
    }
}