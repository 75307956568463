import {useLocation} from "react-router-dom";
import ErrorPage from "./ErrorPage";

export default function Error404() {
    const location = useLocation()

    return <ErrorPage>
        We couldn't find the page you're looking for.
        <br/>
        <small>Your path: <code>{location.pathname}</code></small>
    </ErrorPage>
}
