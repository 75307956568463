import {useSnackbar} from "notistack";
import {WorkSession} from "../../../functions/entities/WorkSession";
import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";
import WorkSessionTable from "../../particles/HR/WorkSessionTable";
import ClapMessage from "../../particles/Other/ClapMessage";
import RefreshButton from "../../particles/Buttons/RefreshButton";
import GoBackButton from "../../particles/Buttons/GoBackButton";
import useGetCollection from "../../../functions/hooks/useGetObject";

export default function Pending() {
    const {enqueueSnackbar} = useSnackbar()
    const {data, loading, error, errorMsg, refresh} = useGetCollection<WorkSession>("/HumanResources/Sessions/0")

    if (loading) return <CenteredLoadingCircle/>
    if (error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
    }

    return <>
        <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
            <GoBackButton path={"/HR"} />
            <RefreshButton refresh={refresh} />
        </div>
        {data.length === 0 ? <ClapMessage /> : <WorkSessionTable data={data} disabled={false}/>}
    </>
}
