import {Assignment} from "../../../functions/entities/Assignment";
import {Item} from "./Item";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Assignee from "../../../functions/entities/Assignee";
import CircularSkeleton from "../loading/CircularSkeleton";
import {EmployeeAvatar} from "../Employees/EmployeeAvatar";
import useGetCollection from "../../../functions/hooks/useGetObject";

export function BoardCard({item}: { item: Assignment }) {

    return <>
        <Item className={"assignmentCard"}>
            <Box>
                <Typography variant={"h5"}>{item.subject}</Typography>
                <Typography variant={"body2"}>{item.description}</Typography>
                {item.status === 2 || item.status === 0 ? <DisplayAssignees itemId={item.id} /> : <></>}
            </Box>
        </Item>
    </>
}
function DisplayAssignees({itemId}:{itemId:number}) {
    const {loading, data : assignees, error} = useGetCollection<Assignee>(`/Assignment/Assignees/${itemId}`)
    if(error) return <></>
    if(loading) return <CircularSkeleton diameter={20} count={3} />

    let keyCounter = 0
    return <div className={"flexElements"}>
        {assignees.map(e => <EmployeeAvatar diameter={20} key={keyCounter++} employee={e.user} my={1} />)}
    </div>
}
