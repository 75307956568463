import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4RoundedIcon from "@mui/icons-material/Brightness4Rounded";
import * as React from "react";
import {useMyTheme} from "../../../functions/hooks/useMyTheme";
import {Tooltip} from "@mui/material";

export function ThemeToggleBtn() {
    const theme = useMyTheme()
    return <Tooltip title={"Toggle theme"}>
        <IconButton onClick={theme.toggle} color="inherit">
        <Badge>
            {theme.mode === "dark" ? <Brightness7Icon/> : <Brightness4RoundedIcon /> }
        </Badge>
    </IconButton>
    </Tooltip>
}
