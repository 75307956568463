import {useLocation} from "react-router-dom";
import {capitalizeFirstLetterOrKeepCapital, extractPageName} from "../string";

export default function useDocumentTitle() {
    const location = useLocation()
    updateTitle(location.pathname)

    function updateTitle(path:string) {
        document.title = `BQA | ${capitalizeFirstLetterOrKeepCapital(extractPageName(path))}`
    }

    return {update: updateTitle, title: capitalizeFirstLetterOrKeepCapital(extractPageName(location.pathname))}
}
