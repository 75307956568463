import {useWaitForDateProgress} from "../../../functions/date";
import Box from "@mui/material/Box";
import {CircularProgress, Tooltip} from "@mui/material";

export function ProgressUntilRefresh({lastRefresh, msUntilRefresh}: { lastRefresh: Date, msUntilRefresh: number }) {
    const percentage = useWaitForDateProgress(lastRefresh, msUntilRefresh)

    return <Box sx={{position: "absolute", bottom: 10, right: 10}}>
        <Tooltip title={"Refreshing..."}>
            <CircularProgress size={20} color={"info"} variant="determinate"
                              value={percentage}/>
        </Tooltip>
    </Box>
}
