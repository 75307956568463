import {PaletteMode} from "@mui/material";

export function getLocalTheme(): PaletteMode {
    if (localStorage.getItem("thememode") == null) {
        return window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"
    }
    try {
        return localStorage.getItem("thememode") as PaletteMode
    } catch {
        return "light"
    }
}

export function setLocalTheme(mode:string) {
    localStorage.setItem("thememode", mode)
}
