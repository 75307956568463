import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {putObject} from "../../../functions/objectService";

const textFieldStyling = {
    margin: 1
}

export default function ModifyPassword() {
    const {enqueueSnackbar} = useSnackbar()
    const [oldPw, setOldPw] = useState("")
    const [newPw, setNewPw] = useState("")
    const [newPw2, setNewPw2] = useState("")

    async function handleSubmit() {
        if (oldPw.length === 0) {
            enqueueSnackbar("Don't forget to insert your old password first!", {variant: "error"})
            return
        }
        if (newPw !== newPw2) {
            enqueueSnackbar("Failed to verify new password, please ensure that both fields contain the same value.", {variant: "error"})
            return
        }
        try {
            await putObject<{old: string, new: string}>("/Authentication/Password", {old: oldPw, new: newPw})
            enqueueSnackbar(`Successfully modified password.`, {variant: "success"})
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Box className={"flexElements centerElements"}>
        <div>
            <Typography variant={"h4"} sx={{pb: 3}}>Modify Password</Typography>
            <Box>
                <TextField type={"password"} sx={textFieldStyling} label={"Old password"} value={oldPw} onChange={(e) => setOldPw(e.target.value)} />
            </Box>
            <Box>
                <TextField type={"password"} sx={textFieldStyling} label={"New password"} value={newPw} onChange={(e) => setNewPw(e.target.value)} />
                <TextField type={"password"} sx={textFieldStyling} error={newPw !== newPw2} label={"Verify password"} value={newPw2} onChange={(e) => setNewPw2(e.target.value)} />
            </Box>
            <Button onClick={handleSubmit} sx={textFieldStyling} variant={"contained"}>Modify Password</Button>
        </div>
    </Box>
}
