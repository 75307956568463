import {defaultEmployee, Employee} from "./Employee";

export interface WorkSession {
    id: number
    user: Employee
    started: Date
    ended: Date
    status: -1|0|1
    activeSession: boolean
}

export function defaultWorkSession() : WorkSession {
    let startDate = new Date((new Date()).setMinutes(-60*5))
    let endDate = new Date((new Date()).setMinutes(0))
    return {
        id: 0,
        user: defaultEmployee(),
        started: startDate,
        ended: endDate,
        status: 0,
        activeSession: false,
    }
}

export function getWorkSessionStatus(statusId: number) {
    if(statusId === -1) return "Rejected"
    if(statusId === 1) return "Approved"
    return "Pending"
}
