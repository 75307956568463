import {Blueprint} from "../../../../functions/entities/Blueprint";
import {useState} from "react";
import Paper from "@mui/material/Paper";
import {Button, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";
import {postObject, putObject} from "../../../../functions/objectService";
import {useNavigate, useParams} from "react-router-dom";

const fieldStyle = {width: "98%", m:1}

export default function Form({defaultBlueprint}:{defaultBlueprint:Blueprint}) {
    const { id } = useParams<{ id:string }>();
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()
    const [blueprint, setBlueprint] = useState(defaultBlueprint)

    function handleChange(attribute:string, value:string) {
        setBlueprint({...blueprint, [attribute] : value})
    }

    function handleNumericalChange(attribute:string, value:string) {
        setBlueprint({...blueprint, [attribute] : Number.parseInt(value)})
    }

    async function handleSubmit() {
        try {
            if(isCreateMode(blueprint)) await postBlueprint()
            else await putBlueprint()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    async function putBlueprint() {
        await putObject<Blueprint>("/Factory/Blueprint/"+id ?? 0, blueprint)
        enqueueSnackbar(`Successfully modified blueprint.`, {variant: "success"})
    }

    async function postBlueprint() {
        let entity = await postObject<Blueprint>("/Factory/Blueprint/"+id ?? 0, blueprint)
        enqueueSnackbar(`Successfully added blueprint.`, {variant: "success"})
        navigate(`/Factories/${id}/blueprint/${entity.data.id}`)
    }

    return <Paper sx={{p: 2}}>
        <Typography variant={"h5"}>{isCreateMode(blueprint) ? "Create" : "Modify"} Blueprint</Typography>
        <TextField error={blueprint.name.length === 0} sx={fieldStyle} label={"Name"} value={blueprint.name} onChange={(e) => handleChange("name", e.target.value)} />
        <TextField error={blueprint.width <= 0} sx={fieldStyle} type={"number"} label={"Width (m)"} value={blueprint.width} onChange={(e) => handleNumericalChange("width", e.target.value)} />
        <TextField error={blueprint.height <= 0} sx={fieldStyle} type={"number"} label={"Length (m)"} value={blueprint.height} onChange={(e) => handleNumericalChange("height", e.target.value)} />
        <div className={"flexElements centerElements"}>
            <Button onClick={handleSubmit} sx={{m:1}} variant={"contained"}>{isCreateMode(blueprint) ? "Create" : "Modify"} Blueprint</Button>
        </div>
    </Paper>
}

function isCreateMode(blueprint:Blueprint) : boolean {
    return !(blueprint.id > 0)
}
