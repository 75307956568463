import {useState} from "react";
import EmployeeTable from "../../particles/Employees/EmployeeTable";
import useEmployees from "../../../functions/hooks/useEmployees";
import StandardListOptions from "../../particles/navigation/StandardListOptions";

export default function List() {
    const [filter, setFilter] = useState("")
    const employees = useEmployees()

    return <>
        <StandardListOptions type="Employees" singleType={"Employee"} filter={filter} setFilter={setFilter} refresh={employees.refresh} />
        <EmployeeTable employees={employees.employees} filter={filter} />
    </>
}
