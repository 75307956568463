import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {BoardCard} from "./Card";
import * as React from "react";
import {useState} from "react";
import {Assignment} from "../../../functions/entities/Assignment";
import {ModifyAssignmentForm} from "./ModifyForm";

export default function AssignmentManagementCard({item} : {item:Assignment}) {
    const [open, setOpen] = useState(false)

    function close() {
        setOpen(false)
    }

    return (
        <div>
            <>
                <div onClick={() => setOpen(true)}>
                    <BoardCard item={item} />
                </div>
                <SwipeableDrawer
                    anchor={"right"}
                    open={open}
                    onClose={close}
                    onOpen={() => setOpen(true)}
                >
                    {<ModifyAssignmentForm close={close} item={item} />}
                </SwipeableDrawer>
            </>
        </div>
    );
}
