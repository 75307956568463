import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {deleteObject} from "../../../functions/objectService";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import {Factory} from "../../../functions/entities/Factory";
import useFactories from "../../../functions/hooks/useFactories";

export function DeleteFactoryDialog({factory, open, closeMethod} :{factory:Factory, open:boolean, closeMethod:() => void}) {
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()
    const factoryContext = useFactories()

    async function handleDelete() {
        try {
            const request = await deleteObject<Factory>("/Factory/Delete/"+factory.id)
            enqueueSnackbar(`Successfully deleted ${request.data.name}`, {variant: "success"})
            navigate("/Factories")
            factoryContext.refresh()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Dialog open={open}>
        <DialogTitle id="responsive-dialog-title">
            {"Delete factory"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you wish to delete <b>{`${factory.name}`}</b> from the system?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={closeMethod}>
                <ArrowBackIcon /> No
            </Button>
            <Button onClick={handleDelete} autoFocus>
                <DeleteIcon />
                Yes
            </Button>
        </DialogActions>
    </Dialog>
}
