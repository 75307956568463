import {Address, addressToString} from "./Address";

export interface Factory {
    id: number
    name: string
    address?: Address | null | undefined
}

export function filterFactory(filterUsed:string, factory : Factory) : boolean {
    const filter = filterUsed.toLowerCase()
    if((factory.id+"").toLowerCase().includes(filter.toLowerCase())) return true
    if((factory.name+"").toLowerCase().includes(filter.toLowerCase())) return true
    if(factory.address !== undefined) return (addressToString(factory.address) + "").toLowerCase().includes(filter.toLowerCase());
    return false
}

export function defaultFactory() : Factory {
    return {
        id: 0,
        name: "",
        address: null
    }
}