import {Employee, employeeFullName} from "../../../functions/entities/Employee";
import {Autocomplete, TextField} from "@mui/material";
import {dateOnlyToString} from "../../../functions/date";

export function SelectEmployee({selected, setSelected, list, defaultValue = null} : {selected:Employee|null, setSelected:(e:Employee|null) => void, list:Employee[], defaultValue?:Employee|null}) {
    return <Autocomplete
        value={selected}
        onChange={(e, v) => setSelected(v)}
        options={list}
        getOptionLabel={(option) => `${employeeFullName(option)} (${option.phone !== null ? option.phone : dateOnlyToString(option.dateOfBirth)})`}
        defaultValue={defaultValue}
        isOptionEqualToValue={(a,b) => a.id === b.id}
        renderInput={(params) => (
            <TextField margin={"dense"} label={"Employee"} {...params} placeholder="Select employee"/>
        )}
        sx={{width: '500px', mb: 1}}
    />
}
