import {useSnackbar} from "notistack";
import Complaint from "../../../functions/entities/Complaint";
import CenteredLoadingCircle from "../loading/CenteredCircularProgress";
import Typography from "@mui/material/Typography";
import {ComplaintCard} from "./Card";
import Divider from "@mui/material/Divider";
import useGetCollection from "../../../functions/hooks/useGetObject";

export default function PendingComplaints() {
    const {enqueueSnackbar} = useSnackbar()
    const {loading:loadingPending, data:pending, error:errorPending, errorMsg:errorMsgPending, refresh} = useGetCollection<Complaint>("/Complaint/Pending")

    let counter = 0

    if(loadingPending) return <CenteredLoadingCircle />
    if(pending.length === 0) return <></>
    if(errorPending) {
        enqueueSnackbar(errorMsgPending, {variant: "error"})
        return <></>
    }

    return <>
        <Typography variant={"h4"} sx={{mb:1}} color={"text.primary"}>Pending</Typography>
        <div className={"flexElements"}>
            {pending.map(e => <ComplaintCard key={counter++} complaint={e} refresh={refresh} />)}
        </div>
        <Divider sx={{my:2}} />
    </>
}
