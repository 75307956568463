import {Button, TextField} from "@mui/material";
import useAuth from "../../../functions/hooks/useAuth";
import {useState} from "react";
import {getAuthToken, putAuthToken} from "../../../functions/authToken";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {putObjectWithResponse} from "../../../functions/objectService";
import {useSnackbar} from "notistack";
import {User} from "../../../functions/entities/User";

const textFieldStyling = {
    margin: 1
}

export default function Profile() {
    const {enqueueSnackbar} = useSnackbar()
    const auth = useAuth()
    const [user, setUser] = useState<User>(auth.user as User)

    function handleChange(attribute:string, newValue:string) {
        setUser({...user, [attribute]: newValue})
    }

    async function handleSubmit() {
        let latestToken : string|null = null
        try {
            if(auth.user?.firstname !== user.firstname && false) { // && false because it doesn't need to be modified
                 const response = await putObjectWithResponse<{ entity:string }, string>("/Account/Firstname", {entity: user.firstname})
                latestToken = response.data
                enqueueSnackbar("Modified your firstname", {variant: "success"})
            }
            if(auth.user?.lastname !== user.lastname && false) { // && false because it doesn't need to be modified
                const response = await putObjectWithResponse<{ entity:string }, string>("/Account/Lastname", {entity: user.lastname})
                latestToken = response.data
                enqueueSnackbar("Modified your lastname", {variant: "success"})
            }
            if(auth.user?.phone !== user.phone) {
                const response = await putObjectWithResponse<{ entity:string }, string>("/Account/Phone", {entity: user.phone})
                latestToken = response.data
                enqueueSnackbar("Modified your phone", {variant: "success"})
            }
            if(auth.user?.profilePicture !== user.profilePicture) {
                const response = await putObjectWithResponse<{ entity:string }, string>("/Account/ProfilePicture", {entity: user.profilePicture})
                latestToken = response.data
                enqueueSnackbar("Modified your profile picture", {variant: "success"})
            }
        } catch (e) {
            enqueueSnackbar(""+e, {variant: "error"})
        } finally {
            latestToken != null && putAuthToken(latestToken)
            auth.setToken(getAuthToken())
        }
    }

    return <Box className={"flexElements centerElements"}>
        <div>
            <Typography variant={"h4"} sx={{pb: 3}}>Profile</Typography>
            <Box>
                <div>
                    <TextField disabled sx={textFieldStyling} label={"Firstname"} value={user?.firstname ?? ""} onChange={(e) => handleChange("firstname",e.target.value)} />
                    <TextField disabled sx={textFieldStyling} label={"Lastname"} value={user?.lastname ?? ""} onChange={(e) => handleChange("lastname",e.target.value)} />
                </div>
                <div>
                    <TextField sx={{...textFieldStyling, width: "97%"}} label={"Profile Picture"} value={user?.profilePicture ?? ""} type={"url"} onChange={(e) => handleChange("profilePicture",e.target.value)} />
                </div>
                <div>
                    <TextField disabled sx={textFieldStyling} label={"Email"} value={user?.email ?? ""} />
                    <TextField sx={textFieldStyling} label={"Phone"} value={user?.phone ?? ""} type={"tel"} onChange={(e) => handleChange("phone",e.target.value)} />
                </div>
            </Box>
            <Button onClick={handleSubmit} sx={textFieldStyling} variant={"contained"}>Modify Profile</Button>
        </div>
    </Box>
}
