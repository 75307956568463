import {CloudFile, CloudFileType} from "../../../functions/entities/CloudFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import StorageIcon from "@mui/icons-material/Storage";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ImageIcon from "@mui/icons-material/Image";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import ArticleIcon from "@mui/icons-material/Article";
import FolderIcon from "@mui/icons-material/Folder";
import PieChartIcon from '@mui/icons-material/PieChart';
import File from "../../../functions/entities/File";

function getExtension(name: string) {
    if (!name.includes('.')) return null
    return name.split('.').reverse()[0]
}

export default function FileIcon({file}: { file: CloudFile|File }) {
    if ("type" in file && file.type === CloudFileType.Directory) return <FolderIcon/>

    const extension = getExtension(file.name)
    if (extension === "pdf"
        || extension === "docx") return <PictureAsPdfIcon/>
    if (extension === "xls"
        || extension === "csv"
        || extension === "xlsx"
        || extension === "json") return <PieChartIcon/>
    if (extension === "zip"
        || extension === "rar") return <StorageIcon/>
    if (extension === "js"
        || extension === "ts"
        || extension === "cs"
        || extension === "java"
        || extension === "html") return <DataObjectIcon/>
    if (extension === "jpg"
        || extension === "jpeg"
        || extension === "png"
        || extension === "gif"
        || extension === "webp") return <ImageIcon/>
    if (extension === "mp4"
        || extension === "mkv"
        || extension === "mp3") return <VideoLibraryIcon/>

    return <ArticleIcon/>
}
