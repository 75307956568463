const TOKEN_KEY = "BQA_TOKEN"

export function putAuthToken(token: string | undefined) {
    if (token === undefined) localStorage.removeItem(TOKEN_KEY)
    else localStorage.setItem(TOKEN_KEY, token)
}

export function getAuthToken(): string | undefined {
    return localStorage.getItem(TOKEN_KEY) ?? undefined
}
