import Paper from "@mui/material/Paper";
import SubscribersComponent from "./../../particles/Newsletter/Subscribers"
import Typography from "@mui/material/Typography";

export default function Subscribers() {
    return <Paper sx={{p:3}}>
        <Typography variant={"h4"} sx={{mb:2}}>Subscribers</Typography>
        <SubscribersComponent/>
    </Paper>
}
