import {Route, Routes} from "react-router-dom";
import React from "react";
import VideoCall from "../../pages/Videocall/VideoCall";
import CreateCall from "../../pages/Videocall/CreateCall";
import Error404 from "../../pages/Errors/404";

export default function CallsRouter() {
    return <>
        <Routes>
            <Route path={"/"}>
                <Route path={"/VideoCall/:callname"} element={<VideoCall/>}/>
                <Route path={"/General"} element={<VideoCall/>}/>
                <Route index element={<CreateCall/>}/>
                <Route path="*" element={<Error404/>}/>
            </Route>
        </Routes>
    </>
}