import {Employee, filterEmployee} from "../../../functions/entities/Employee";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Paper from "@mui/material/Paper";

export const employeeTableHeader = ["Firstname", "Lastname", "Phone"]
let counter = 0
export default function EmployeeTable({employees, filter = ""} : {employees : Employee[],filter?:string}) {
    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {employeeTableHeader.map((title) => <TableCell sx={{textAlign: "center"}} key={++counter}>{title}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {employees.filter(e => filterEmployee(filter, e)).map((e) => {
                    return <EmployeeRow key={++counter} employee={e} />
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

function EmployeeRow(entity : { employee: Employee }) {
    const navigate = useNavigate()
    const [mouseEntered, setMouseEntered] = useState(false)

    function handleClick() {
        navigate("/Employees/"+entity.employee.id)
    }

    return <TableRow className={"clickableRow"} sx={mouseEntered ? {cursor: "pointer"} : {}} onClick={handleClick} onMouseEnter={() => setMouseEntered(true)} onMouseLeave={() => setMouseEntered(false)}>
        <TableCell align={"center"}>{entity.employee.firstname}</TableCell>
        <TableCell align={"center"}>{entity.employee.lastname}</TableCell>
        <TableCell align={"center"}>{entity.employee.phone}</TableCell>
    </TableRow>
}
