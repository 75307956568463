import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Button, ButtonGroup} from "@mui/material";
import {useMyTheme} from "../../functions/hooks/useMyTheme";

export default function Settings() {
    return <div className={"flexElements centerElements"}>
        <Box component={Paper} sx={{p: 3, width: "fit-content"}}>
            <Typography variant={"h4"} sx={{pb:3}}>Settings</Typography>
            <Grid className={"flexElements spaceBetweenElements"} container spacing={2}>
                <Grid item>
                    <Typography sx={{p:1, mr: 5}}>Theme</Typography>
                </Grid>
                <Grid item>
                    <ThemeSetting />
                </Grid>
            </Grid>
        </Box>
    </div>
}

export function ThemeSetting() {
    const theme = useMyTheme()

    return <ButtonGroup disableElevation variant="contained">
        <Button onClick={theme.toggle} disabled={theme.mode === "light"}>Light</Button>
        <Button onClick={theme.toggle} disabled={theme.mode === "dark"}>Dark</Button>
    </ButtonGroup>
}
