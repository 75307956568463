import useEmployees from "../../../functions/hooks/useEmployees";
import {useState} from "react";
import {defaultUser} from "../../../functions/entities/User";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {postObjectWithResponse} from "../../../functions/objectService";

const fieldStyle = {m: 1}
export default function RegisterForm() {
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()
    const employeeContext = useEmployees()
    const [user, setUser] = useState(defaultUser)

    function handleChange(attribute:string, value:string) {
        setUser({...user, [attribute] : value})
    }

    async function postUser() {
        try {
            const newUser = {
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                phone: user.phone
            }
            if((await postObjectWithResponse<object, boolean>("/Authentication/Register", newUser)).data) {
                enqueueSnackbar(`Created new user account for employee.`, {variant: "success"})
                employeeContext.refresh()
                navigate("/Employees")
            } else {
                enqueueSnackbar(`An issue has occurred, failed to create user account for employee.`, {variant: "error"})
            }
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Box component={Paper} sx={{p: 5, display: "flex", flexDirection: "column"}}>
        <Typography variant={"h4"}>Add Employee</Typography>
        <TextField
            sx={fieldStyle}
            label={"Firstname"}
            value={user.firstname}
            onChange={(e) => handleChange("unique_name", e.target.value)}
        />
        <TextField
            sx={fieldStyle}
            label={"Lastname"}
            value={user.lastname}
            onChange={(e) => handleChange("family_name", e.target.value)}
        />
        <TextField
            sx={fieldStyle}
            label={"Email"}
            type={"email"}
            value={user.email}
            onChange={(e) => handleChange("email", e.target.value)}
        />
        <TextField
            sx={fieldStyle}
            label={"Phone"}
            type={"tel"}
            value={user.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
        />
        <Box className={"flexElements centerElements"}>
            <Button onClick={postUser} variant={"contained"} color={"primary"} sx={{...fieldStyle, width: "fit-content"}}>
                <CheckIcon />
                Add
            </Button>
        </Box>
    </Box>
}
