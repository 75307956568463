import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";
import {WorkSession} from "../../../functions/entities/WorkSession";
import {useSnackbar} from "notistack";
import WorkSessionTable from "../../particles/HR/WorkSessionTable";
import {Button} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import CreateWorkSessionDialog from "../../particles/HR/CreateWorkSessionDialog";
import NoResults from "../../particles/Other/NoResults";
import QuickSessionButton from "../../particles/HR/QuickSessionButton";
import useGetCollection from "../../../functions/hooks/useGetObject";

export default function MySessions() {
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()
    const [openNewSessionWindow, setOpenNewSessionWindow] = useState(false)
    const {data, loading, error, errorMsg, refresh} = useGetCollection<WorkSession>("/HumanResources/Sessions")

    if (loading) return <CenteredLoadingCircle/>
    if (error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
    }

    return <>
        <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
            <Button onClick={() => navigate("/HR")} variant={"contained"} sx={{mr: 1, mb: 1}}>
                <ArrowBackIcon/> Go back
            </Button>
            <QuickSessionButton />
            <Button variant={"contained"} sx={{mb: 1}} onClick={() => setOpenNewSessionWindow(true)}>
                <AddIcon /> New session
            </Button>
        </div>
        {data.length > 0 ?
            <WorkSessionTable data={data} displayName={false} displayModifyOptions={false}/>
            :
            <NoResults />
        }
        <CreateWorkSessionDialog refresh={refresh} open={openNewSessionWindow} setOpen={setOpenNewSessionWindow} />
    </>
}
