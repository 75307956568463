import {Route, Routes} from "react-router-dom";
import Error404 from "../../pages/Errors/404";
import React from "react";
import New from "../../pages/Newsletters/New";
import List from "../../pages/Newsletters/List";
import Modify from "../../pages/Newsletters/Modify";
import Subscribers from "../../pages/Newsletters/Subscribers";
import Get from "../../pages/Newsletters/Get";

export default function NewsletterRouter() {
    return <Routes>
        <Route path={"/"}>
            <Route path={"/New"} element={<New />}/>
            <Route path={"/Subscribers"} element={<Subscribers />}/>
            <Route path={"/:letterId"} element={<Get />}/>
            <Route path={"/Modify/:letterId"} element={<Modify />}/>
            <Route index element={<List />}/>
            <Route path="/*" element={<Error404 />} />
        </Route>
    </Routes>
}
