import Typography from "@mui/material/Typography";
import errorImg from "./../../../media/img/error.svg"
import {useMediaQuery} from "@mui/material";

const errorImgElement = <img src={errorImg} style={{height: 150, marginRight: 50}} alt={"Error screen"}/>

export default function ErrorPage(props: { children: (JSX.Element | string)[] }) {
    const media = useMediaQuery("(min-width:700px)")

    return <div className={"flexElements centerElements"}>
        {media ? errorImgElement : <></>}
        <main>
            <Typography color={"text.primary"} variant="h2">An error has occured!</Typography>
            <Typography color={"text.primary"} variant={"body1"}>{props.children}</Typography>
        </main>
    </div>
}
