import {Route, Routes} from "react-router-dom";
import React from "react";
import {FactoryProvider} from "../../../functions/hooks/useFactories";
import Modify from "../../pages/Factories/Modify";
import Get from "../../pages/Factories/Get";
import List from "../../pages/Factories/List";
import New from "../../pages/Factories/New";
import NewBlueprint from "../../pages/Factories/Blueprint/New";
import NewItem from "../../pages/Factories/Items/New";
import ModifyBlueprint from "../../pages/Factories/Blueprint/Modify";
import Error404 from "../../pages/Errors/404";

export default function FactoriesRouter() {
    return <FactoryProvider>
        <Routes>
            <Route path={"/"}>
                <Route path={"/:id/Modify"} element={<Modify/>}/>
                <Route path={"/:id/Blueprint/New"} element={<NewBlueprint/>}/>
                <Route path={"/:id/Blueprint/:blueprintId"} element={<ModifyBlueprint/>}/>
                <Route path={"/:id/Items/New"} element={<NewItem/>}/>
                <Route path={"/:id/Items/:itemId"} element={<>Item detail page</>}/>
                <Route path={"/:id"} element={<Get/>}/>
                <Route path={"/New"} element={<New/>}/>
                <Route index element={<List/>}/>
                <Route path="*" element={<Error404/>}/>
            </Route>
        </Routes>
    </FactoryProvider>
}
