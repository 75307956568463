import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import * as React from "react";
import useAuth from "../../../functions/hooks/useAuth";
import AuthorizedItems from "../../particles/navigation/AuthorizedItems";
import UnauthorizedItems from "../../particles/navigation/UnauthorizedItems";
import Typography from "@mui/material/Typography";
import {useMyTheme} from "../../../functions/hooks/useMyTheme";

export default function LeftNav({open, toggleDrawer, setOpen} : {open: boolean, toggleDrawer: () => void, setOpen: (e:boolean) => void}) {
    const auth = useAuth()
    const theme = useMyTheme()

    function handleMouseAction(isEntering:boolean) {
        if(window.innerWidth > 1000) {
            if(isEntering) setOpen(true)
            else setOpen(false)
        }
    }

    return <Drawer variant="permanent" onMouseEnter={() => handleMouseAction(true)} onMouseLeave={() => handleMouseAction(false)} open={open}>
        <Toolbar
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
            }}
        >
            <Typography onClick={toggleDrawer} fontWeight={"bold"} color={theme.mode === "light" ? "text.secondary" : "primary.light"} align={"center"} fontSize={18} sx={{width: "100%"}}>BioQuestAlliance</Typography>
            <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
            </IconButton>
        </Toolbar>
        <Divider />
        <auth.Authorized>
            <AuthorizedItems />
        </auth.Authorized>
        <auth.NotAuthorized>
            <UnauthorizedItems />
        </auth.NotAuthorized>
    </Drawer>
}

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);
