export interface HandleRowsParams {
    currentRows:number, newRows:number, totalRows:number, setRows:(e:number) => void
}
export function handleRows(currentRows:number, newRows:number, totalRows:number, setRows:(e:number) => void) {
    if(currentRows > totalRows && currentRows < newRows) {
        console.log(`The maximum rows available has already been reached.`)
        return;
    }
    if (newRows < 5) {
        console.log(`The minimum rows available has already been reached.`)
        return;
    }
    if (currentRows < newRows) setRows(currentRows + 5)
    if (currentRows > newRows) setRows(currentRows - 5)
}
