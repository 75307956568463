import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Factory} from "../../../functions/entities/Factory";
import {addressToString} from "../../../functions/entities/Address";

export function FactoryDetails({factory}: {factory:Factory}) {
    return <Paper sx={{p: 2, mt: 1}}>
        <Typography variant={"h4"}>
            <>{`${factory.name}`} #{factory.id}</>
        </Typography>
        <Typography sx={{mt:2}}>
            <b>Address:</b> {factory.address === null ? "/" : addressToString(factory.address)}
        </Typography>
    </Paper>
}
