import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {postObject} from "../../../functions/objectService";
import {useSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

export default function Form({refresh}: { refresh: () => void }) {
    const {enqueueSnackbar} = useSnackbar()
    const [editorOpen, setEditorOpen] = useState(false)
    const [title, setTitle] = useState('')
    const [value, setValue] = useState('')


    async function onSubmit() {
        try {
            if (title.length < 2) {
                enqueueSnackbar('Title must be longer than 2 characters.', {variant: "error"})
                return;
            }
            if (title.length > 50) {
                enqueueSnackbar('Title must be shorter than 50 characters.', {variant: "error"})
                return;
            }
            if (value.length < 5) {
                enqueueSnackbar('Post must be longer than 5 characters.', {variant: "error"})
                return;
            }
            await postObject("/Wall/Post", {
                title: title,
                content: value
            })
            await refresh()
        } catch(e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    if(!editorOpen) return <Box component={Paper} sx={{p: 1}}>
        <Button variant={"contained"} onClick={() => setEditorOpen(true)}>Create Post</Button>
    </Box>

    return <Box component={Paper} sx={{p: 3, py:1}}>
        <div className={"flexElements centerElements"}>
            <TextField label={"Title"} fullWidth margin={"dense"} value={title} onChange={(e) => setTitle(e.target.value)}/>
            <TextField label={"Post"} fullWidth margin={"dense"} value={value} onChange={(e) => setValue(e.target.value)} multiline/>
            <Button variant={"contained"} onClick={onSubmit} sx={{mx:1}}>Create Post</Button>
            <Button variant={"contained"} onClick={() => setEditorOpen(false)} color={"error"} sx={{mx:1}}>Cancel</Button>
        </div>
    </Box>
}
