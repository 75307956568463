import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Section(props: { children: React.ReactNode, title: string, visible:boolean, actionArea?:React.ReactNode|undefined }) {
    if(!props.visible) return <></>
    return <Box sx={{m: 2}}>
        <Box className={"flexElements"}>
            <Typography variant={"subtitle2"} color={"gray"} sx={{m: 1, ml: 4, mb: 0.3}}
                        fontSize={15}>{props.title}</Typography>
            <Box sx={{ml:3}}>{props.actionArea ?? <></>}</Box>
        </Box>
        <Box className={"flexElements"}>
            {props.children}
        </Box>
    </Box>
}
