import {defaultEmployee, Employee, filterEmployee} from "./Employee";
import {beautyDateLong} from "../date";

export default interface Complaint {
    id: number
    complainant: Employee
    accused: Employee
    reason: string
    status: 0|1|-1
    handler?: Employee|null
    verdictNotes:null|string
    created:Date
}

export function filterComplaint(filter:string, complaint:Complaint) : boolean {
    if(filterEmployee(filter, complaint.accused)) return true
    if(filterEmployee(filter, complaint.complainant)) return true
    if(complaint.handler !== null && complaint.handler !== undefined && filterEmployee(filter, complaint.handler)) return true
    if((complaint.reason+"").toLowerCase().includes((filter+"").toLowerCase())) return true
    if((complaint.verdictNotes+"").toLowerCase().includes((filter+"").toLowerCase())) return true
    if(getComplaintStatus(complaint.status).toLowerCase().includes((filter+"").toLowerCase())) return true
    return beautyDateLong(complaint.created).toLowerCase().includes((filter + "").toLowerCase());

}

export function defaultComplaint() : Complaint {
    return {
        id: 0,
        complainant: defaultEmployee(),
        accused: defaultEmployee(),
        reason: '',
        status: 0,
        handler: null,
        verdictNotes:null,
        created: new Date()
    }
}

export function getComplaintStatus(status: 0|1|-1) {
    switch (status) {
        case 1: return "Valid"
        case -1: return "Invalid"
        default: return "Pending"
    }
}


export function getComplaintStatusColor(status: 0|1|-1) {
    switch (status) {
        case 1: return "success"
        case -1: return "error"
        default: return "secondary"
    }
}
