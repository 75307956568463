import {useNavigate, useParams} from "react-router-dom";
import useDocumentTitle from "../../../functions/hooks/useDocumentTitle";
import Error404 from "../Errors/404";
import {Button} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useFactories from "../../../functions/hooks/useFactories";
import {Factory} from "../../../functions/entities/Factory";
import FactoryForm from "../../particles/Factories/FactoryForm";

export default function Modify() {
    const { id } = useParams<{ id:string }>();
    const title = useDocumentTitle()
    const entityContext = useFactories()

    const entityIndex = entityContext.factories.findIndex(e => e.id+"" === id)
    if(entityIndex < 0) {
        title.update("Error 404")
        return <Error404 />
    }
    title.update(`Modifying ${entityContext.factories[entityIndex].name}`)

    return <>
        <FactoryModifyOptions factory={entityContext.factories[entityIndex]} />
        <FactoryForm defaultFactory={entityContext.factories[entityIndex]} />
    </>
}

function FactoryModifyOptions({factory} :{factory:Factory}) {
    const navigate = useNavigate()
    return <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
        <div>
            <Button onClick={() => navigate("/Factories/"+factory.id)} variant={"contained"} sx={{mr: 1, mb: 1}}>
                <ArrowBackIcon/> Go back
            </Button>
        </div>
    </div>
}
