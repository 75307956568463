import {Route, Routes} from "react-router-dom";
import {Home} from "../../pages/Home";
import Error400 from "../../pages/Errors/400";
import Error404 from "../../pages/Errors/404";
import React from "react";
import Navigation from "../navigation/Navigation";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import {Footer} from "../../particles/Footer";
import Box from "@mui/material/Box";
import useDocumentTitle from "../../../functions/hooks/useDocumentTitle";
import useAuth from "../../../functions/hooks/useAuth";
import {Login} from "../../pages/Authentication/Login";
import EmployeesRouter from "./EmployeesRouter";
import {MyTheme} from "../../../functions/hooks/useMyTheme";
import Account from "../../pages/Account";
import FactoriesRouter from "./FactoriesRouter";
import Settings from "../../pages/Settings";
import HrRouter from "./HrRouter";
import AssignmentsRouter from "./AssignmentsRouter";
import NewsletterRouter from "./NewsletterRouter";
import LogbookRouter from "./LogbookRouter";
import WallPosts from "../../pages/Wall/WallPosts";
import CloudRouter from "./CloudRouter";
import CallsRouter from "./CallsRouter";

export function MainRouter() {
    useDocumentTitle()
    const auth = useAuth()

    if (!auth.isAuthorized()) return <Login/>

    return <>
        <MyTheme>
            <Navigation/>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar/>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <Routes>
                        <Route path="/">
                            <Route index element={<Home/>}/>
                            <Route path="/Employees/*" element={<EmployeesRouter/>}/>
                            <Route path="/Factories/*" element={<FactoriesRouter/>}/>
                            <Route path="/Assignments/*" element={<AssignmentsRouter/>}/>
                            <Route path="/HR/*" element={<HrRouter/>}/>
                            <Route path="/Newsletters/*" element={<NewsletterRouter/>}/>
                            <Route path="/Account" element={<Account/>}/>
                            <Route path="/Settings" element={<Settings/>}/>
                            <Route path="/Wall" element={<WallPosts/>}/>
                            <Route path="/Logbook/*" element={<LogbookRouter/>}/>
                            <Route path="/Cloud/*" element={<CloudRouter/>}/>
                            <Route path="/CallSystem/*" element={<CallsRouter/>}/>
                            <Route path="/400" element={<Error400/>}/>
                            <Route path="/*" element={<Error404/>}/>
                        </Route>
                    </Routes>
                    <Footer sx={{pt: 4}}/>
                </Container>
            </Box>
        </MyTheme>
    </>
}
