import {Route, Routes} from "react-router-dom";
import React from "react";
import List from "../../pages/Employees/List";
import Get from "../../pages/Employees/Get";
import New from "../../pages/Employees/New";
import {EmployeeProvider} from "../../../functions/hooks/useEmployees";
import Modify from "../../pages/Employees/Modify";
import Error404 from "../../pages/Errors/404";

export default function EmployeesRouter() {
    return <EmployeeProvider>
        <Routes>
            <Route path={"/"}>
                <Route path={"/:id/Modify"} element={<Modify/>}/>
                <Route path={"/:id"} element={<Get/>}/>
                <Route path={"/New"} element={<New/>}/>
                <Route index element={<List/>}/>
                <Route path="*" element={<Error404/>}/>
            </Route>
        </Routes>
    </EmployeeProvider>
}
