import {useState} from "react";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileModal from "./UploadFileModal";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

export default function UploadFileButton({category}:{category:string|null}) {
    const [open, setOpen] = useState(false)

    return <>
        <Tooltip title={`Upload file to ${category ?? 'ROOT'}`}>
            <IconButton color={"primary"} onClick={() => setOpen(true)}>
                <FileUploadIcon/>
            </IconButton>
        </Tooltip>
        <UploadFileModal open={open} handleClose={() => setOpen(false)} category={category} />
    </>
}

export function UploadFileButtonToNewCategory() {
    const [open, setOpen] = useState(false)

    return <>
        <Tooltip title={`Upload file to new category`}>
            <IconButton color={"primary"} onClick={() => setOpen(true)}>
                <DriveFolderUploadIcon/>
            </IconButton>
        </Tooltip>
        <UploadFileModal open={open} handleClose={() => setOpen(false)} category={""} allowEditing={true} />
    </>
}