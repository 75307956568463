import {useSnackbar} from "notistack";
import {postObject} from "../../../functions/objectService";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import {Button} from "@mui/material";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {useGetSingle} from "../../../functions/hooks/useGetObject";

export default function QuickSessionButton() {
    const {enqueueSnackbar} = useSnackbar()
    const {data : activeSession, loading, error, errorMsg, refresh} = useGetSingle<boolean>("/HumanResources/HasSession", false)

    if(loading) {
        return <Button sx={{mb:1}} startIcon={<HourglassTopIcon />} variant={"contained"} disabled>Loading work session</Button>
    }

    if(error) {
        enqueueSnackbar(errorMsg)
        return <></>
    }

    async function toggleSession() {
        if(activeSession) {
            await postObject("/HumanResources/EndSession", {})
        } else {
            await postObject("/HumanResources/StartSession", {})
        }
        await refresh()
    }

    return <Button
        onClick={toggleSession}
        variant={"contained"}
        sx={{mb:1}}
        color={activeSession ? "error" : "primary"}
        startIcon={activeSession ? <StopIcon /> : <PlayArrowIcon />}>
            {activeSession ? "End" : "Start"} work session
    </Button>
}
