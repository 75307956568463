import Typography from "@mui/material/Typography";
import * as React from "react";

export function Footer(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Copyright ©
            BioQuestAlliance{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
}
