import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Skeleton} from "@mui/material";

export function Tile(props: {children:React.ReactNode, xs:number, md:number, lg:number, height:number|string}) {
    return <Grid item xs={props.xs} md={props.md} lg={props.lg} sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: props.height,
        overflowY: "auto"
    }}>
        {props.children}
    </Grid>
}


export function LoadingTile(props: {xs:number, md:number, lg:number, height:number|string}) {
    return <Grid item xs={props.xs} md={props.md} lg={props.lg}>
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: props.height,
            }}
        >
            <Skeleton sx={{height:"100%", width:"100%"}} />
        </Paper>
    </Grid>
}
