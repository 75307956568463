import {useNavigate} from "react-router-dom";
import {Card, CardActionArea} from "@mui/material";
import Typography from "@mui/material/Typography";
import useAuth from "../../../functions/hooks/useAuth";
import {WorkSession} from "../../../functions/entities/WorkSession";
import {minutesBetween} from "../../../functions/date";
import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";
import {useSnackbar} from "notistack";
import {SummaryCard} from "../../particles/Other/SummaryCard";
import {employeeFullName} from "../../../functions/entities/Employee";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import MySessions from "../../particles/HR/MySessions";
import {handleWorkSessions} from "../../../functions/accessDefined";
import useGetCollection, {useGetSingle} from "../../../functions/hooks/useGetObject";

export default function HrIndex() {
    const {data, error, firstLoading} = useGetSingle<number>("/HumanResources/CountPendingSessions", 0)
    const {Authorized, NotAuthorized} = useAuth()

    function anyPending() {
        if (error || firstLoading) return true
        return data > 0
    }

    return <>
        <div className={"flexElements centerElements"}>
            <Authorized roles={["HR", "UpperManagement", "LocalManagement"]} anyRole={true}>
                <>
                    {anyPending() ? <MenuCard title={`Pending requests (${data})`}
                                              description={"A list of unhandled work sessions."}
                                              path={"/HR/Sessions/Pending"}/> : <></>}
                    <MenuCard title={"All sessions"} description={"All employees with their sessions"}
                              path={"/HR/Sessions/All"}/>
                </>
            </Authorized>
            <MenuCard title={"My sessions"} description={"All reported work hours."} path={"/HR/Sessions"}/>
            <MenuCard title={"Complaints"} description={"Create or view complaints."} path={"/HR/Complaints"}/>
        </div>
        <Authorized roles={handleWorkSessions()} anyRole={true}>
            <div style={{marginTop: 10}}>
                <SummaryCardArea/>
            </div>
        </Authorized>
        <NotAuthorized roles={handleWorkSessions()} anyRole={true}>
            <div style={{marginTop: 10}}>
                <MySessions/>
            </div>
        </NotAuthorized>
    </>
}

function SummaryCardArea() {
    const {enqueueSnackbar} = useSnackbar()
    const {data, loading, error, errorMsg} = useGetCollection<Array<WorkSession>>("/HumanResources/SessionSummary")

    if (loading) return <CenteredLoadingCircle/>
    if (error) {
        enqueueSnackbar(`${errorMsg}`, {variant: "error"})
        return <></>
    }

    const reformed = data.map(e => {
        return {
            user: e[0].user,
            minutes: e.map(b => minutesBetween(b.started, b.ended)).reduce((a, b) => a + b, 0)
        }
    })

    return <div style={{marginTop: 10}} className={"flexElements centerElements"}>
        {reformed.map(e => <SummaryCard key={e.user.id} Icon={WorkHistoryIcon} description={employeeFullName(e.user)}
                                        number={Math.round(e.minutes / 60) + "h"}/>)}
    </div>
}

export function MenuCard({title, description, path}: { title: string, description: string, path: string }) {
    const navigate = useNavigate()
    return <Card sx={{m: 1}}>
        <CardActionArea onClick={() => navigate(path)} sx={{p: 3}}>
            <Typography gutterBottom variant="h5" component="div">
                {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {description}
            </Typography>
        </CardActionArea>
    </Card>
}

