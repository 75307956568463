import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {ItemType} from "../../../../functions/entities/Item";
import {Button} from "@mui/material";

export default function New() {
    const [itemType, setItemType] = useState<ItemType>(ItemType.Tool)

    return <Paper sx={{p: 2}}>
        <Typography variant={"h5"}>New {ItemType[itemType]}</Typography>
        <div className={"flexElements"}>
            <ChangeItemTypeButton currentType={itemType} type={ItemType.Tool} onChange={setItemType}/>
            <ChangeItemTypeButton currentType={itemType} type={ItemType.Device} onChange={setItemType}/>
            <ChangeItemTypeButton currentType={itemType} type={ItemType.Machine} onChange={setItemType}/>
            <ChangeItemTypeButton currentType={itemType} type={ItemType.Building} onChange={setItemType}/>
        </div>
    </Paper>
}

function ChangeItemTypeButton({
                                  onChange,
                                  type,
                                  currentType
                              }: { onChange: (e: ItemType) => void, type: ItemType, currentType: ItemType }) {
    return <Button disabled={currentType === type} onClick={() => onChange(type)}>{ItemType[type]}</Button>
}
