import {Button} from "@mui/material";
import {DeleteEmployeeDialog} from "./DeleteEmployeeDialog";
import {useState} from "react";
import {Employee} from "../../../functions/entities/Employee";
import useAuth from "../../../functions/hooks/useAuth";
import {managementRoles} from "../../../functions/accessDefined";

export default function DeleteEmployeeButton({employee}:{employee:Employee}) {
    const auth = useAuth()
    const [openDeleteMenu, setOpenDeleteMenu] = useState(false)

    if(!auth.isAuthorized(managementRoles(), true)) return <></>

    return <>
        <Button variant={"contained"} color={"error"} onClick={() => setOpenDeleteMenu(true)} sx={{mb: 1}}>
            Delete Employee
        </Button>
        <DeleteEmployeeDialog employee={employee} open={openDeleteMenu}
                              closeMethod={() => setOpenDeleteMenu(false)}/>
    </>
}
