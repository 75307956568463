import {Factory} from "./Factory";

export interface Blueprint {
    id: number
    name: string
    height: number
    width: number
    factory?: Factory
}

export function defaultBlueprint() : Blueprint {
    return {
        id: 0,
        name: "",
        height: 0,
        width: 0
    }
}