import Complaint, {getComplaintStatus, getComplaintStatusColor} from "../../../functions/entities/Complaint";
import useAuth from "../../../functions/hooks/useAuth";
import {useSnackbar} from "notistack";
import {useState} from "react";
import {putObject} from "../../../functions/objectService";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {employeeFullName} from "../../../functions/entities/Employee";
import {beautyDateLong} from "../../../functions/date";
import {Button, Chip, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {handleComplaints} from "../../../functions/accessDefined";

const requireNotesLength = 20
export function ComplaintCard({complaint, refresh}: { complaint: Complaint, refresh?: () => void }) {
    const auth = useAuth()
    const {enqueueSnackbar} = useSnackbar()
    const [status, setStatus] = useState(complaint.status)
    const [notes, setNotes] = useState(complaint.verdictNotes ?? '')
    const [openModify, setOpenModify] = useState(false)

    async function handleSubmit() {
        try {
            if (notes.length < requireNotesLength) throw new Error("You must write something under notes.")
            if(complaint.status === status && notes === complaint.verdictNotes) throw new Error("No modifications have been made.")
            await putObject('/Complaint/Handle', {
                id: complaint.id,
                status: status,
                verdictNotes: notes
            })
            enqueueSnackbar("Successfully modified complaint", {variant: "success"})
            if(refresh !== undefined) await refresh()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Box component={Paper} sx={{p: 3, maxWidth: 270, m:0.5}}>
        <Typography variant={"h5"}>{employeeFullName(complaint.accused)}</Typography>
        <Typography variant={"subtitle2"}
                    sx={{wordWrap: "break-word", mb: 1}}>{beautyDateLong(complaint.created)}</Typography>
        <Chip sx={{mb: 1}} label={getComplaintStatus(complaint.status)}
              color={getComplaintStatusColor(complaint.status)}/>
        <Typography variant={"body2"}
                    sx={{wordWrap: "break-word", my: 1, mx: 2, color: "gray"}}>{complaint.reason}</Typography>
        <auth.Authorized roles={handleComplaints()} anyRole={true}><>
            {!openModify ? <div className={"flexElements centerElements"}><Button onClick={() => setOpenModify(true)} sx={{mt:2}} variant={"contained"}>{complaint.status === 0 ? 'Set' : 'Modify'} verdict</Button></div> : <>
                <div className={"flexElements centerElements"}>
                    <ToggleButtonGroup fullWidth size="small" aria-label="small button group" sx={{mt: 1, mb: 2}} exclusive value={status}
                                       onChange={(e, v) => setStatus(v)}>
                        <ToggleButton value={-1} color={"primary"}>
                            Invalid
                        </ToggleButton>
                        <ToggleButton value={0} color={"warning"}>
                            Pending
                        </ToggleButton>
                        <ToggleButton value={1} color={"error"}>
                            Valid
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <TextField fullWidth margin={"dense"} value={notes} onChange={(e) => setNotes(e.target.value)}
                           label={"Verdict notes"} error={notes.length < requireNotesLength}
                           helperText={notes.length < requireNotesLength ? `Verdict notes must be longer than ${requireNotesLength} characters. (Counted ${notes.length})` : null}/>
                <div className={"flexElements centerElements"}>
                    <Button onClick={handleSubmit} variant={"contained"} sx={{mt:1}}>
                        Post decision
                    </Button>
                </div>
            </>}
        </>
        </auth.Authorized>
    </Box>
}
