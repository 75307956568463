import {useState} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import GoBackButton from "../../particles/Buttons/GoBackButton";
import {useNavigate, useParams} from "react-router-dom";
import RedirectButton from "../../particles/Buttons/RedirectButton";
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';

export default function New() {
    return <>
        <div className={"flexElements centerElements"}>
            <div style={{width: "fit-content"}}>
                <div className={"flexElements spaceBetweenElements"}>
                    <RedirectButton path={"/CallSystem/General"} text="General Call" icon={ <HeadsetMicIcon></HeadsetMicIcon> }/>
                </div>
                <div className={"flexElements centerElements"}>
                    <NewForm/>
                </div>
            </div>
        </div>
    </>
}

function NewForm() {
        const navigate = useNavigate()
        const [callName, setReason] = useState('')
        async function handleSubmit() {
        try {
            if (callName.length < 10) throw "You must write at least 10 characters."
            navigate("/CallSystem/VideoCall/"+callName)
        } catch (e) {
            
        }
    }

    return <Box component={Paper} sx={{p: 1, width: "fit-content"}}>
        <div>
            <Typography variant={"h4"} sx={{mb: 2}}>Join Room</Typography>
            <TextField
                fullWidth
                label={"Join Room"}
                margin={"dense"}
                multiline
                minRows={1}
                error={callName.length < 10}
                helperText={callName.length < 10 ? `The callname must be at least 10 characters long. (counted: ${callName.length})` : null}
                value={callName}
                onChange={(e) => setReason(e.target.value)}/>
            <div className={"flexElements centerElements"}>
                <Button sx={{mt: 1}} variant={"contained"} onClick={handleSubmit}>Go To Room</Button>
            </div>
        </div>
    </Box>
}