import {useState} from "react";
import useFactories from "../../../functions/hooks/useFactories";
import FactoriesTable from "../../particles/Factories/FactoriesTable";
import StandardListOptions from "../../particles/navigation/StandardListOptions";

export default function List() {
    const [filter, setFilter] = useState("")
    const factories = useFactories()

    return <>
        <StandardListOptions type="Factories" singleType={"Factory"} filter={filter} setFilter={setFilter} refresh={factories.refresh} />
        <FactoriesTable factories={factories.factories} filter={filter} />
    </>
}