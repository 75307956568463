import {Assignment} from "../entities/Assignment";
import {createContext, useContext} from "react";
import CenteredLoadingCircle from "../../components/particles/loading/CenteredCircularProgress";
import Error500 from "../../components/pages/Errors/500";
import useGetCollection, {usePolling} from "./useGetObject";
import {ProgressUntilRefresh} from "../../components/particles/loading/ProgressUntilRefresh";

const defaultContextPar = {
    assignments: [],
    refresh: () => {
    }
}

interface BoardContextInterface {
    assignments: Assignment[]
    refresh: () => void
}


const BoardContext = createContext<BoardContextInterface>(defaultContextPar)

export function useBoard() {
    return useContext(BoardContext)
}

export function BoardProvider({children}: { children: JSX.Element | JSX.Element[] }) {
    const {data, refresh, loading, error, hasLoaded, lastLoad} = useGetCollection<Assignment>("/Assignment/Get")
    const {refreshRate} = usePolling(refresh, error, 5)

    if (loading && !hasLoaded) return <CenteredLoadingCircle/>
    if (error && data.length === 0) {
        return <Error500/>
    }

    /*function updateStatus(id:number, newStatus:AssignmentStatus) {
        let allItems=items;
        allItems=allItems.map(item=>{
            if(item.id===id){
                console.log('in here')
                item.status=newStatus;
            }
            return item
        })
        setItems(allItems)
    }*/

    return <BoardContext.Provider value={{
        assignments: data,
        refresh: refresh
    }}>
        {children}
        <ProgressUntilRefresh lastRefresh={lastLoad} msUntilRefresh={refreshRate}/>
    </BoardContext.Provider>
}
