import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {MainRouter} from "./components/routing/routers/MainRouter";
import {Auth} from "./functions/hooks/useAuth";
import {BrowserRouter as Router} from "react-router-dom";
import "./media/css/Elements.scss"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './media/css/App.scss'
import {NotificationProvider} from "./functions/hooks/useNotifications";


export default function App() {
    return (
        <NotificationProvider>
            <Auth>
                <Router>
                    <Box sx={{display: 'flex'}}>
                        <CssBaseline/>
                        <MainRouter/>
                    </Box>
                </Router>
            </Auth>
        </NotificationProvider>
    );
}
