export interface User {
    primarysid: string
    firstname: string
    lastname: string
    profilePicture: string
    email: string
    role: string[]|string
    phone: string
    exp: number
}

export function defaultUser() : User {
    return {
        primarysid: "",
        firstname: "",
        lastname: "",
        profilePicture: "",
        email: "",
        role: [],
        phone: "",
        exp: 0
    }
}
