import HomeIcon from '@mui/icons-material/Home';
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import * as React from "react";
import FactoryIcon from '@mui/icons-material/Factory';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WorkIcon from '@mui/icons-material/Work';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {NavigationItem as NavigationListItem} from "./NavigationItem";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BusinessIcon from '@mui/icons-material/Business';
import ArticleIcon from '@mui/icons-material/Article';
import useAuth from "../../../functions/hooks/useAuth";
import {logbookAccess, newsletterAccess} from "../../../functions/accessDefined";
import CloudIcon from '@mui/icons-material/Cloud';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export default function AuthorizedItems() {
    const auth = useAuth()
    return <List component="nav">
        <NavigationListItem path="/" text="Dashboard" icon={<HomeIcon/>}/>
        <NavigationListItem path="/Factories" text="Factories" icon={<FactoryIcon/>}/>
        <NavigationListItem path="/Employees" text="Employees" icon={<PeopleAltIcon/>}/>
        <Divider sx={{my: 1}}/>
        <NavigationListItem path="/Assignments" text="Assignments" icon={<WorkIcon/>}/>
        <NavigationListItem path="/HR" text="Human Resources" icon={<AdminPanelSettingsIcon/>}/>
        <NavigationListItem path="/Wall" text="Wall" icon={<BusinessIcon/>}/>
        <NavigationListItem path="/Cloud" text="Cloud" icon={<CloudIcon/>}/>
        <NavigationListItem path="/CallSystem" text="Video Call" icon={<LocalPhoneIcon/>}/>
        <auth.Authorized roles={newsletterAccess()} anyRole={true}><>
            <Divider sx={{my: 1}}/>
            <NavigationListItem path="/Newsletters" text="Newsletters" icon={<AutoStoriesIcon/>}/>
        </></auth.Authorized>
        <auth.Authorized roles={logbookAccess()} anyRole={true}><>
            <Divider sx={{my: 1}}/>
            <NavigationListItem path="/Logbook" text="Logbook" icon={<ArticleIcon/>}/>
        </></auth.Authorized>
    </List>
}
