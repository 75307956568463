import {Employee} from "../../../functions/entities/Employee";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import useEmployees from "../../../functions/hooks/useEmployees";
import {deleteObject} from "../../../functions/objectService";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

export function DeleteEmployeeDialog({employee, open, closeMethod} :{employee:Employee, open:boolean, closeMethod:() => void}) {
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()
    const employeeContext = useEmployees()

    async function handleDelete() {
        try {
            await deleteObject<Employee>("/Authentication/Delete/"+employee.id)
            enqueueSnackbar(`Successfully deleted user`, {variant: "success"})
            navigate("/Employees")
            employeeContext.refresh()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Dialog open={open}>
        <DialogTitle id="responsive-dialog-title">
            {"Delete employee"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to erase <b>{`${employee.firstname} ${employee.lastname}`}</b> from the system?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={closeMethod} sx={{mr:2}}>
                <ArrowBackIcon /> Cancel
            </Button>
            <Button onClick={handleDelete} autoFocus color={"error"}>
                <DeleteIcon sx={{mr:0.5}} />
                DELETE
            </Button>
        </DialogActions>
    </Dialog>
}
