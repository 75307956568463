import {CircularProgress, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";

interface loadingCircleProps {
    label?: string;
    margin?: number;
}

export default function CenteredLoadingCircle({margin = 5, label = "Loading.."}: loadingCircleProps) {
    return <Tooltip title={label}>
        <Box className={"flexElements centerElements"} sx={{position: 'relative', margin: margin}}>
            <CircularProgress color="inherit"/>
        </Box>
    </Tooltip>
}
