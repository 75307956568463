import {useLocation} from "react-router-dom";
import ErrorPage from "./ErrorPage";

export default function Error500() {
    const location = useLocation()

    return <ErrorPage>
        I'm sorry but we couldn't load the page for you.
        <br/>
        <small>Your path: <code>{location.pathname}</code></small>
    </ErrorPage>
}
