import GoBackButton from "../../particles/Buttons/GoBackButton";
import RefreshButton from "../../particles/Buttons/RefreshButton";
import {WorkSession} from "../../../functions/entities/WorkSession";
import {useParams} from "react-router-dom";
import LoadingTable from "../../particles/loading/Table";
import {useSnackbar} from "notistack";
import WorkSessionTable from "../../particles/HR/WorkSessionTable";
import useAuth from "../../../functions/hooks/useAuth";
import {useState} from "react";
import {TextField} from "@mui/material";
import moment from "moment";
import NoResults from "../../particles/Other/NoResults";
import {handleWorkSessions} from "../../../functions/accessDefined";
import useGetCollection from "../../../functions/hooks/useGetObject";

const dateSx = {p: 0.1, width: 100}

function getQueryDate(year:number, month:number, type: ("min"|"max")) {
    if(type === "min") {
        return moment().month(month).year(year).startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss')
    }
    return moment().month(month).year(year).endOf('month').endOf('day').format('YYYY-MM-DDTHH:mm:ss');
}

export default function UserSessions() {
    const auth = useAuth()
    const {enqueueSnackbar} = useSnackbar()
    const {userId} = useParams<{ userId: string }>()
    const [month, setMonth] = useState(moment().month())
    const [year, setYear] = useState(moment().year())
    const {
        loading,
        data,
        error,
        errorMsg,
        refresh
    } = useGetCollection<WorkSession>(`/HumanResources/Sessions/${userId}/${getQueryDate(year, month, "min")}/${getQueryDate(year, month, "max")}`)

    if (error) enqueueSnackbar(`${errorMsg}`, {variant: "error"})
    if (loading || error) return <>
        <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
            <GoBackButton path={"/HR/Sessions/All"}/>
            <div>
                <TextField sx={dateSx} margin={"dense"} label={"Month"} type={"number"} value={month} />
                <TextField sx={dateSx} margin={"dense"} label="Year" type={"number"} value={year} />
            </div>
            <div></div>
        </div>
        <LoadingTable columns={5} rows={20}/>
    </>

    function setMonthHandler(value:string|null) {
            try {
                if(value !== undefined && value !== null && value?.length > 0) {
                    const val = Number.parseInt(value ?? "0")-1
                    if(val > 11 || val < 0) return;
                    setMonth(val)
                }
            } catch {
                console.log("Ignoring empty month value.")
            }
    }

    return <auth.Authorized roles={handleWorkSessions()} anyRole={true} displayErrorMsg={true}>
            <>
                <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
                    <GoBackButton path={"/HR/Sessions/All"}/>
                    <div>
                        <TextField onChange={(e) => setMonthHandler(e.target.value)} sx={dateSx} label={"Month"} type={"number"} value={month+1} margin={"dense"} />
                        <TextField onChange={(e) => setYear(Number.parseInt(e.target.value))} sx={dateSx} label="Year" type={"number"} value={year} margin={"dense"} />
                    </div>
                    <RefreshButton refresh={refresh}/>
                </div>
                {data.length > 0 ?
                    <WorkSessionTable data={data} displayName={false} displayModifyOptions={true} disabled={false}/>
                    :
                    <NoResults />
                }
            </>
        </auth.Authorized>
}
