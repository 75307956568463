import {defaultEmployee, Employee} from "./Employee";

export interface Assignment {
    id: number
    subject: string
    status: AssignmentStatus
    description: string
    created: Date
    lastModified: Date
    creator:Employee
    priority:AssignmentPriorityStatus
}

export type AssignmentStatus = 0|1|2|-1
export type AssignmentPriorityStatus = 0|1|2|3|4

export function testAssignments() : Assignment[] {
    return [
        {
            id: 1,
            subject:'Manage ORM for client XYZ',
            status:0,
            description: 'The description comes here.',
            created: new Date(),
            lastModified: new Date(),
            creator: defaultEmployee(),
            priority: 2
        },
        {
            id: 2,
            subject:'Review Summer Intern project report',
            status:0,
            description: 'The description comes here.',
            created: new Date(),
            lastModified: new Date(),
            creator: defaultEmployee(),
            priority: 2
        },
        {
            id: 3,
            subject:'Host Landing Page for Gerry Pizza Shop',
            status:0,
            description: 'The description comes here.',
            created: new Date(),
            lastModified: new Date(),
            creator: defaultEmployee(),
            priority: 2
        },
        {
            id: 4,
            subject:'Release Junior Developer payment',
            status:1,
            description: 'The description comes here.',
            created: new Date(),
            lastModified: new Date(),
            creator: defaultEmployee(),
            priority: 2
        },
        {
            id: 5,
            subject:'Discuss Digital Marketing requirements ',
            status:1,
            description: 'The description comes here.',
            created: new Date(),
            lastModified: new Date(),
            creator: defaultEmployee(),
            priority: 2
        },
        {
            id: 6,
            subject:'Discuss technology budget with CTO',
            status:0,
            description: 'The description comes here.',
            created: new Date(),
            lastModified: new Date(),
            creator: defaultEmployee(),
            priority: 2
        }
    ]
}

export function getAssignmentStatusName(value:number) {
    switch (value) {
        case 1: return 'Completed'
        case 0: return 'Backlog'
        case 2: return 'Ongoing'
        default: return 'Cancelled'
    }
}

export function defaultAssignment() : Assignment {
    return {
        id: 0,
        subject:'',
        status:0,
        description: '',
        created: new Date(),
        lastModified: new Date(),
        creator: defaultEmployee(),
        priority: 2
    }
}