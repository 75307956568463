import {useNavigate, useParams} from "react-router-dom";
import useDocumentTitle from "../../../functions/hooks/useDocumentTitle";
import useEmployees from "../../../functions/hooks/useEmployees";
import Error404 from "../Errors/404";
import {Employee} from "../../../functions/entities/Employee";
import {Button} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RolePicker from "../../particles/Account/RolePicker";
import {EmployeeDetails} from "../../particles/Employees/EmployeeDetails";
import Box from "@mui/material/Box";
import useAuth from "../../../functions/hooks/useAuth";
import {managementRoles} from "../../../functions/accessDefined";
import Error400 from "../Errors/400";

export default function Modify() {
    const auth = useAuth()
    const { id } = useParams<{ id:string }>();
    const title = useDocumentTitle()
    const employeeContext = useEmployees()

    if(!auth.isAuthorized(managementRoles(), true)) return <Error400 />

    const employeeIndex = employeeContext.employees.findIndex(e => e.id+"" === id)
    if(employeeIndex < 0) {
        title.update("Error 404")
        return <Error404 />
    }
    title.update(`Modifying ${employeeContext.employees[employeeIndex].firstname}`)

    return <>
        <EmployeeModifyOptions employee={employeeContext.employees[employeeIndex]} />
        <p>Unfortunately the feature to modify someone else's account has not yet been implemented.</p>
        <Box className={"flexElements"}>
            <Box sx={{m: 1}}>
                <EmployeeDetails employee={employeeContext.employees[employeeIndex]} />
            </Box>
            <Box sx={{m: 1}}>
                <RolePicker userId={id+""} />
            </Box>
        </Box>
    </>
}

function EmployeeModifyOptions({employee} :{employee:Employee}) {
    const navigate = useNavigate()
    return <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
        <div>
            <Button onClick={() => navigate("/Employees/"+employee.id)} variant={"contained"} sx={{mr: 1, mb: 1}}>
                <ArrowBackIcon/> Go back
            </Button>
        </div>
    </div>
}
