import {createContext, useContext} from "react";
import {SnackbarProvider, useSnackbar, VariantType} from "notistack";

interface PossibleNotifications {
    success: (e: string) => void
    error: (e: string) => void
    warning: (e: string) => void
    default: (e: string) => void
    info: (e: string) => void
}

const NotificationsContext = createContext<PossibleNotifications>({
    success: (e: string) => {
        console.log(e)
    },
    error: (e: string) => {
        console.log(e)
    },
    warning: (e: string) => {
        console.log(e)
    },
    default: (e: string) => {
        console.log(e)
    },
    info: (e: string) => {
        console.log(e)
    }
})

export default function useNotifications() {
    return useContext(NotificationsContext)
}

export function NotificationBridge({children}: { children: JSX.Element | JSX.Element[] }) {
    const {enqueueSnackbar} = useSnackbar()

    function fixErrorMsgAndSend(msg: string, variant: VariantType | undefined) {
        msg = msg.replaceAll("Axios", "")
        if (msg.toLowerCase().includes("network error")) msg = "Failed to connect to network."
        enqueueSnackbar(msg, {variant: variant})
    }

    function success(e: string) {
        fixErrorMsgAndSend(e, "success")
    }

    function error(e: string) {
        fixErrorMsgAndSend(e, "error")
    }

    function warning(e: string) {
        fixErrorMsgAndSend(e, "warning")
    }

    function defaultN(e: string) {
        fixErrorMsgAndSend(e, "default")
    }

    function info(e: string) {
        fixErrorMsgAndSend(e, "info")
    }

    return <NotificationsContext.Provider value={{
        success: success,
        error: error,
        warning: warning,
        default: defaultN,
        info: info
    }}>
        <SnackbarProvider maxSnack={5}>
            {children}
        </SnackbarProvider>
    </NotificationsContext.Provider>
}

export function NotificationProvider({children}: { children: JSX.Element | JSX.Element[] }) {
    return <SnackbarProvider maxSnack={5}>
        <NotificationBridge>
            {children}
        </NotificationBridge>
    </SnackbarProvider>
}
