import Typography from "@mui/material/Typography";
import {BoardProvider} from "../../../functions/hooks/useBoard";
import {BoardLists} from "../../particles/Assignments/BoardLists";
import "./../../../media/css/Assignments.scss"

export default function Board() {
    return <BoardProvider>
        <Typography variant={"h3"} sx={{color: "text.primary", mb: 1}}>Assignments</Typography>
        <BoardLists />
    </BoardProvider>
}
