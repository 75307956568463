import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";
import {defaultWorkSession, WorkSession} from "../../../functions/entities/WorkSession";
import {useSnackbar} from "notistack";
import {postObject} from "../../../functions/objectService";
import {getDateFormValue, timeBetweenString} from "../../../functions/date";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

export default function CreateWorkSessionDialog({
                                                    open,
                                                    setOpen,
                                                    refresh
                                                }: { open: boolean, setOpen: (e: boolean) => void, refresh: () => Promise<any> }) {
    const {enqueueSnackbar} = useSnackbar()
    const [workSession, setWorkSession] = useState<WorkSession>(defaultWorkSession)

    async function handleSubmit() {
        try {
            await postObject<WorkSession>("/HumanResources/Session", workSession)
            enqueueSnackbar(`Successfully added work session.`, {variant: "success"})
            setOpen(false)
            await refresh()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    const handleChange = (attribute:string, newValue: string | null) => {
        if (newValue === null) return;
        setWorkSession({...workSession, [attribute]: newValue})
    };

    return <LocalizationProvider dateAdapter={AdapterMoment}><Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>New work session</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Duration: {timeBetweenString(workSession.started, workSession.ended)}
            </DialogContentText>
            <DateTimePicker
                label="Start of session"
                value={getDateFormValue(workSession.started)}
                onChange={(value) => handleChange("started",value)}
                renderInput={(params) => <TextField margin={"dense"} fullWidth {...params} />}
            />
            <DateTimePicker
                label="End of session"
                value={getDateFormValue(workSession.ended)}
                onChange={(value) => handleChange("ended",value)}
                renderInput={(params) => <TextField margin={"dense"} fullWidth {...params} />}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={handleSubmit}>Add</Button>
        </DialogActions>
    </Dialog>
    </LocalizationProvider>
}
