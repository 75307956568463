import {Employee} from "../../../functions/entities/Employee";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Avatar} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import {dateOnlyToString} from "../../../functions/date";

export function EmployeeDetails({employee}: {employee:Employee}) {
    return <Paper className={"flexElements"} sx={{p: 2, px: 5}}>
        <div>
            <Avatar
                src={employee.profilePicture ?? ""}
                sx={{bgcolor: "primary.light", m: 3, ml: 0, mr: 4, height: 100, width: 100, fontSize: 80}}>
                <PersonIcon sx={{fontSize: 70}} />
            </Avatar>
        </div>
        <div>
            <Typography variant={"h4"}>
                {`${employee.firstname} ${employee.lastname}`}
            </Typography>
            <Typography sx={{mt:2}}>
                <b>Email:</b> <a href={`mailto:${employee.email}`}>{employee.email}</a>
            </Typography>
            <Typography sx={{mt:2}}>
                <b>Phone:</b> <a href={`tel:${employee.phone}`}>{employee.phone}</a>
            </Typography>
            <Typography sx={{mt:2}}>
                <b>Date of birth:</b> {dateOnlyToString(employee.dateOfBirth)}
            </Typography>
        </div>
    </Paper>
}
