import {ExtendedWallPost, Opinion, Vote} from "../../../functions/entities/WallPost";
import {VerticalTimelineElement} from 'react-vertical-timeline-component';
import Typography from "@mui/material/Typography";
import {employeeFullName, employeeInitials} from "../../../functions/entities/Employee";
import {Avatar} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import {grey} from "@mui/material/colors";
import {useMyTheme} from "../../../functions/hooks/useMyTheme";
import {useNavigate} from "react-router-dom";
import {ThumbUp} from "@mui/icons-material";
import {ThumbDown} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import useAuth from "../../../functions/hooks/useAuth";
import {deleteObject, postObject} from "../../../functions/objectService";
import {useSnackbar} from "notistack";
import {useState} from "react";


export default function Post({post}: { post: ExtendedWallPost }) {
    const theme = useMyTheme()
    const navigate = useNavigate()

    function toUserProfile() {
        navigate(`/Employees/${post.user.id}`)
    }

    const color = theme.mode === "light" ? "white" : grey[400]

    return <VerticalTimelineElement
        contentStyle={{background: color}} contentArrowStyle={{borderRight: `7px solid ${color}`}}
        icon={<Avatar src={post.user.profilePicture ?? undefined}
                      sx={{width: "100%", height: "100%"}}>{employeeInitials(post.user)}</Avatar>}
        iconStyle={{background: color, color: color, cursor: "pointer"}}
        iconOnClick={toUserProfile}
    >
        <section id={`postId${post.id}`}>
            <Typography variant={"h5"}>{post.title}</Typography>
            <div className={"flexElements"} style={{color: "gray", cursor: "pointer"}} onClick={toUserProfile}>
                <RemoveIcon sx={{fontSize: 20, mt: 0.5, mr: 1}}/> <Typography
                variant={"subtitle1"}>{employeeFullName(post.user)}</Typography>
            </div>
            <span style={{fontWeight: "normal", padding: 5}}>{post.content}</span>
            <div style={{marginTop: 10}}>
                <VoteSection post={post}/>
            </div>
        </section>
    </VerticalTimelineElement>
}

export function VoteSection({post}: { post: ExtendedWallPost }) {
    const auth = useAuth()
    const {enqueueSnackbar} = useSnackbar()
    const [myVote, setMyVote] = useState<0|1|-1>(userGetOpinion(post.opinions, auth.user?.primarysid ?? ''))

    async function handleVote(vote:Vote) {
        try {
            if(vote === myVote) {
                await deleteObject(`/Wall/Vote/${post.id}`)
                enqueueSnackbar(`Successfully removed voted`, {variant: "success"})
                setMyVote(0)
            }
            else {
                await postObject('/Wall/Vote', {
                    postId: post.id,
                    vote: vote
                })
                enqueueSnackbar(`Successfully voted`, {variant: "success"})
                setMyVote(vote)
            }
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    const thumbUpColor =  myVote === 1 ? 'green' : 'text.default'
    const thumbDownColor = myVote === -1 ? 'darkred' : 'text.default'

    return <div className={"flexElements"}>
        <div className={"flexElements centerElements"}>
            <div>
                <IconButton onClick={() => handleVote(1)}>
                    <ThumbUp sx={{color: thumbUpColor}} />
                </IconButton>
            </div>
            <div>
                <Typography variant={"body2"} sx={{color: "gray"}}>{postOpinionCount(post.opinions, 1, myVote, auth.user?.primarysid ?? '')}</Typography>
            </div>
        </div>
        <div className={"flexElements centerElements"}>
            <div>
                <IconButton sx={{ml:3}} onClick={() => handleVote(-1)}>
                    <ThumbDown sx={{color: thumbDownColor}} />
                </IconButton>
            </div>
            <div>
                <Typography variant={"body2"} sx={{color: "gray"}}>{postOpinionCount(post.opinions, -1, myVote, auth.user?.primarysid ?? '')}</Typography>
            </div>
        </div>
    </div>
}

function postOpinionCount(opinions: Opinion[], mustHaveValue: Vote, myVote:0|1|-1, userId:string): number {
    const storedVote = userGetOpinion(opinions, userId)
    let count = 0
    if(storedVote !== 0) {
        if(storedVote === 1 && mustHaveValue === 1) count--
        else if (storedVote === -1 && mustHaveValue === -1) count--
    }
    if(myVote === -1 && mustHaveValue === -1) count += opinions.map(e => e.vote).filter(e => e === mustHaveValue).length+1
    else if(myVote === 1 && mustHaveValue === 1) count += opinions.map(e => e.vote).filter(e => e === mustHaveValue).length+1
    else count += opinions.map(e => e.vote).filter(e => e === mustHaveValue).length
    return count
}

function userGetOpinion(opinions: Opinion[], userId:string): -1|1|0 {
    const mine = opinions.filter(e => (e.user?.id+"") === (userId+""))
    if(mine.length === 0) return 0
    if(mine[0].vote > 0) return 1
    return -1
}
