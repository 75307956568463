import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {postObject, putObject} from "../../../functions/objectService";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Button, TextField} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useFactories from "../../../functions/hooks/useFactories";
import {Factory} from "../../../functions/entities/Factory";

const fieldStyle = {m: 1}

export default function FactoryForm({defaultFactory}: { defaultFactory: Factory }) {
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()
    const factoryContext = useFactories()
    const [factory, setFactory] = useState(defaultFactory)

    function handleChange(attribute:string, value:string) {
        setFactory({...factory, [attribute] : value})
    }

    async function handleSubmit() {
        if(isCreateMode(factory)) await postEntity()
        else await putEntity()
        factoryContext.refresh()
    }

    async function putEntity() {
        try {
            const result = await putObject<Factory>("/Factory/Modify", factory)
            enqueueSnackbar(`Successfully modified ${result.data.name}`, {variant: "success"})
            navigate("/Factories/"+factory.id)
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    async function postEntity() {
        try {
            const result = await postObject<Factory>("/Factory/Add", factory)
            enqueueSnackbar(`Successfully added ${result.data.name}`, {variant: "success"})
            navigate("/Factories/"+result.data.id)
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    return <Box component={Paper} sx={{p: 5, display: "flex", flexDirection: "column"}}>
        <Typography variant={"h4"}>{isCreateMode(factory) ? "Add" : "Modify"} Factory</Typography>
        <TextField
            sx={fieldStyle}
            label={"Name"}
            value={factory.name}
            onChange={(e) => handleChange("name", e.target.value)}
        />
        <Box className={"flexElements centerElements"}>
            <Button onClick={handleSubmit} variant={"contained"} color={"primary"} sx={{...fieldStyle, width: "fit-content"}}>
                <CheckIcon />
                {isCreateMode(factory) ? "Add" : "Modify"}
            </Button>
        </Box>
    </Box>
}

function isCreateMode(entity:Factory) : boolean {
    return entity.id === undefined || entity.id === 0
}
