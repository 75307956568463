import CenteredLoadingCircle from "../loading/CenteredCircularProgress";
import {Chip, FormControl, Input, InputAdornment, InputLabel} from "@mui/material";
import Subscriber from "../../../functions/entities/Subscriber";
import {useSnackbar} from "notistack";
import {deleteObject, postObject} from "../../../functions/objectService";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import SaveIcon from '@mui/icons-material/Save';
import useGetCollection from "../../../functions/hooks/useGetObject";

export default function Subscribers() {
    const [email, setEmail] = useState('')
    const {enqueueSnackbar} = useSnackbar()
    const {data:subscribers, loading, error, errorMsg, refresh} = useGetCollection<Subscriber>("/Newsletter/Subscribers")

    async function handleDelete(subscriber:Subscriber) {
        try {
            await deleteObject(`/Newsletter/Subscriber/${subscriber.id}`)
            enqueueSnackbar(`Removed ${subscriber.email} from subscribers`, {variant: "success"})
            await refresh()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    async function handleNewSubscriber() {
        try {
            if(email.length < 3 || !email.includes('.') || !email.includes('@')) throw new Error("This is not a valid email address")
            await postObject("/Newsletter/Subscriber", {
                email: email
            })
            setEmail('')
            enqueueSnackbar(`Added ${email} to subscribers`, {variant: "success"})
            await refresh()
        } catch (e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    }

    if(error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
    }

    return <>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
            <InputLabel>Email</InputLabel>
            <Input
                id="standard-adornment-password"
                type={'email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleNewSubscriber}
                        >
                            <SaveIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
        <div>
            {loading && <CenteredLoadingCircle />}
            {(!loading && !error) && subscribers.map(e => <Chip key={e.id} sx={{m:0.5}} label={e.email} onDelete={() => handleDelete(e)} />)}
        </div>
    </>
}
