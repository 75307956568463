import {createContext, useContext} from "react";
import CenteredLoadingCircle from "../../components/particles/loading/CenteredCircularProgress";
import {useSnackbar} from "notistack";
import {Factory} from "../entities/Factory";
import useGetCollection from "./useGetObject";

const FactoriesContext = createContext<{factories: Factory[], refresh: () => void}>({factories : [], refresh: () => {}})

export default function useFactories() {
    return useContext(FactoriesContext)
}

export function FactoryProvider({children} : {children:JSX.Element}) {
    const {enqueueSnackbar} = useSnackbar()
    const {loading, data, refresh, error, errorMsg} = useGetCollection<Factory>("/Factory/Get")

    if(loading) return <CenteredLoadingCircle />
    if(error) enqueueSnackbar(errorMsg, {variant: "error"})

    return <FactoriesContext.Provider value={{factories: data, refresh}}>
        {children}
    </FactoriesContext.Provider>
}
