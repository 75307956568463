import {useState} from "react";
import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useSnackbar} from "notistack";
import {postObject} from "../../../functions/objectService";
import Subscribers from "./Subscribers";

export default function DistributePanel({letterId, refreshPage}:{letterId:string|number, refreshPage:() => void}) {
    const {enqueueSnackbar} = useSnackbar()
    const [openDistribute, setOpenDistribute] = useState(false)

    async function onDistribute() {
        setOpenDistribute(false)
        await postObject(`/Newsletter/Distribute/${letterId}`, {})
        setTimeout(refreshPage, 2000)
        enqueueSnackbar('Sent out newsletter!', {variant: "success"})
    }

    return <>
        <Button sx={{mx: 0.5, mb: 1}} onClick={() => setOpenDistribute(true)} variant={"contained"} color={"warning"}>
            <SendIcon /> {<Box sx={{pl: 0.5}}>Distribute</Box>}
        </Button>

        <Dialog open={openDistribute} maxWidth={"md"}>
            <DialogTitle>
                Distribute to all subscribers
            </DialogTitle>
            <DialogContent sx={{overflowY: "auto", maxHeight: 300}}>
                <Subscribers />
            </DialogContent>
            <DialogActions className={"flexElements spaceBetweenElements"}>
                <Button onClick={() => setOpenDistribute(false)} color={"error"}>Cancel</Button>
                <Button onClick={onDistribute} autoFocus color={"warning"}>
                    SEND OUT
                </Button>
            </DialogActions>
        </Dialog>
    </>
}