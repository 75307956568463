import Assignee from "../../../functions/entities/Assignee";
import useEmployees from "../../../functions/hooks/useEmployees";
import {Autocomplete, Button, TextField} from "@mui/material";
import {Employee, employeeFullName} from "../../../functions/entities/Employee";
import {dateOnlyToString} from "../../../functions/date";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {deleteObject, postObjectWithResponse} from "../../../functions/objectService";

export function AssignmentAssignee({assignment, defaultEmployees, refresh}: { assignment: number, defaultEmployees: Assignee[], refresh: () => void }) {
    const {enqueueSnackbar} = useSnackbar()
    const [selected, setSelected] = useState(defaultEmployees.map(e => e.user))
    const employees = useEmployees()

    async function handleSubmit() {
        for (const employee of selected) {
            if(defaultEmployees.filter(b => b.user.id === employee.id).length === 0) await handlePost(employee)
        }
        for (const assignee of defaultEmployees) {
            if(selected.filter(b => b.id === assignee.user.id).length === 0) await handleDelete(assignee)
        }
        //board.refresh()
        await refresh()
    }

    async function handlePost(user:Employee) {
        try {
            await postObjectWithResponse<{assignmentId:number; userId:string},Assignee>("/Assignment/AssignUser", {
                assignmentId: assignment,
                userId: user.id
            })
            enqueueSnackbar(`Successfully added ${employeeFullName(user)}`, {variant: "success"})
        } catch (e) {
            enqueueSnackbar(`${e}`)
        }
    }

    async function handleDelete(assignee:Assignee) {
        try {
            await deleteObject<Assignee>("/Assignment/RemoveUser/"+assignee.id)
            enqueueSnackbar(`Successfully removed ${employeeFullName(assignee.user)}`, {variant: "success"})
        } catch (e) {
            enqueueSnackbar(`${e}`)
        }
    }

    return <>
        <Autocomplete
            multiple
            value={selected}
            onChange={(e, v) => setSelected(v)}
            options={employees.employees}
            getOptionLabel={(option) => `${employeeFullName(option)} (${option.phone !== null ? option.phone : dateOnlyToString(option.dateOfBirth)})`}
            defaultValue={defaultEmployees.map(e => e.user)}
            isOptionEqualToValue={(a,b) => a.id === b.id}
            renderInput={(params) => (
                <TextField {...params} placeholder="Select assignees"/>
            )}
            sx={{width: '500px', mb: 1}}
        />
        <Button variant={"contained"} onClick={handleSubmit}>Modify Assignees</Button>
    </>
}
