export type wallViewOptions = "1-column-left"|"2-columns"|"1-column-right"
const storageName = "wallViewPreference"
export function getWallViewPreference(): wallViewOptions {
    try {
        const item = localStorage.getItem(storageName)
        if(item === null) return "2-columns"
        return (localStorage.getItem(storageName)) as wallViewOptions
    } catch {
        return "2-columns"
    }
}

export function setWallViewPreference(mode:string) {
    if(mode !== "1-column-left" && mode !== "2-columns" && mode !== "1-column-right") mode = "2-columns"
    localStorage.setItem(storageName, mode)
}
