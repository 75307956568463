import {WorkSession} from "../../../functions/entities/WorkSession";
import LoadingTable from "../../particles/loading/Table";
import {minutesBetween} from "../../../functions/date";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {useNavigate} from "react-router-dom";
import GoBackButton from "../../particles/Buttons/GoBackButton";
import RefreshButton from "../../particles/Buttons/RefreshButton";
import useAuth from "../../../functions/hooks/useAuth";
import {Employee} from "../../../functions/entities/Employee";
import NoResults from "../../particles/Other/NoResults";
import {handleWorkSessions} from "../../../functions/accessDefined";
import useGetCollection from "../../../functions/hooks/useGetObject";

export default function All() {
    const auth = useAuth()
    const {data, loading, error, refresh} = useGetCollection<Array<WorkSession>>("/HumanResources/SessionSummary")

    if (loading || error) return <LoadingTable columns={5} rows={20}/>
    const reformed = data.map(e => {
        return {
            user: e[0].user,
            minutes: e.map(b => minutesBetween(b.started, b.ended)).reduce((a, b) => a + b, 0)
        }
    })

    return <>
        <auth.Authorized roles={handleWorkSessions()} anyRole={true} displayErrorMsg={true}>
            <>
                <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
                    <GoBackButton path={"/HR"}/>
                    <RefreshButton refresh={refresh}/>
                </div>
                {reformed.length > 0 ?
                    <AllTable data={reformed} />
                    :
                    <NoResults/>
                }
            </>
        </auth.Authorized>
    </>
}

interface ReformedData {
    user: Employee
    minutes: number
}

function AllTable({data}:{data:ReformedData[]}) {
    const navigate = useNavigate()

    return <Table component={Paper}>
        <TableHead>
            <TableRow>
                <TableCell>Firstname</TableCell>
                <TableCell>Lastname</TableCell>
                <TableCell>Minutes</TableCell>
                <TableCell>Hours</TableCell>
                <TableCell sx={{width: 5}}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map(e => <TableRow>
                <TableCell>{e.user.firstname}</TableCell>
                <TableCell>{e.user.lastname}</TableCell>
                <TableCell>{e.minutes}</TableCell>
                <TableCell>{Math.round(e.minutes / 60)}</TableCell>
                <TableCell>
                    <IconButton onClick={() => navigate(`/HR/Sessions/${e.user.id}`)}>
                        <KeyboardArrowRightIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}
