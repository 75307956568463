import {useSnackbar} from "notistack";
import {useState} from "react";
import {WorkSession} from "../../../functions/entities/WorkSession";
import CenteredLoadingCircle from "../loading/CenteredCircularProgress";
import WorkSessionTable from "./WorkSessionTable";
import NoResults from "../Other/NoResults";
import CreateWorkSessionDialog from "./CreateWorkSessionDialog";
import Error500 from "../../pages/Errors/500";
import useGetCollection from "../../../functions/hooks/useGetObject";

export default function MySessions() {
    const {enqueueSnackbar} = useSnackbar()
    const [openNewSessionWindow, setOpenNewSessionWindow] = useState(false)
    const {data, loading, error, errorMsg, refresh} = useGetCollection<WorkSession>("/HumanResources/RecentSessions")

    if (loading) return <CenteredLoadingCircle/>
    if (error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
        return <Error500 />
    }

    return <>
        {data.length > 0 ?
            <WorkSessionTable data={data} displayName={false} displayModifyOptions={false}/>
            :
            <NoResults />
        }
        <CreateWorkSessionDialog refresh={refresh} open={openNewSessionWindow} setOpen={setOpenNewSessionWindow} />
    </>
}
