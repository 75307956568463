import {createContext, useContext} from "react";
import {Employee} from "../entities/Employee";
import CenteredLoadingCircle from "../../components/particles/loading/CenteredCircularProgress";
import {useSnackbar} from "notistack";
import Error500 from "../../components/pages/Errors/500";
import useGetCollection from "./useGetObject";

const EmployeesContext = createContext<{employees: Employee[], refresh: () => void}>({employees : [], refresh: () => {}})

export default function useEmployees() {
    return useContext(EmployeesContext)
}

export function EmployeeProvider({children} : {children:JSX.Element}) {
    const {enqueueSnackbar} = useSnackbar()
    const {loading, data, refresh, error, errorMsg} = useGetCollection<Employee>("/Account/Get")

    if(loading) return <CenteredLoadingCircle />
    if(error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
        return <Error500 />
    }

    return <EmployeesContext.Provider value={{employees: data, refresh}}>
        {children}
    </EmployeesContext.Provider>
}
