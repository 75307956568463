import axios, {AxiosResponse} from "axios"
import {getAuthToken} from "./authToken";

if(window.location.hostname.includes("localhost")) {
    axios.defaults.baseURL = "https://localhost:7177"
} else {
    axios.defaults.baseURL = "https://backend.bqa-platform.com"
}

function setAuthHeader() {
    const token = getAuthToken()
    if ((token?.length ?? 0) > 10) axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

export async function getObject<T>(path: string) {
    try {
        setAuthHeader()
        return await axios.get<T>(path)
    } catch(e) {
        throw e
    }

}

export async function putObject<T>(path: string, entity: T) {
    setAuthHeader()
    return await axios.put<T>(path, entity)
}

export async function putObjectWithResponse<T, R>(path: string, entity: T) {
    setAuthHeader()
    return await axios.put<T, AxiosResponse<R, any>>(path, entity)
}

export async function postObject<T>(path: string, entity: T) {
    setAuthHeader()
    return await axios.post<T>(path, entity)
}

export async function postFileObject(path: string, filePath: string, file: File) {
    setAuthHeader()
    const formData = new FormData();
    formData.append("file", file);
    //formData.append("path", filePath);
    return await axios.post<FileUploadStatus>(path, formData, {
        data: formData,
        headers: { "Content-Type": "multiple/form-data" }
    })
}

export enum FileUploadStatus {
    Failed,
    Success,
    Skipped
}

export async function postObjectWithResponse<T, R>(path: string, entity: T) {
    setAuthHeader()
    return await axios.post<T, AxiosResponse<R, any>>(path, entity)
}

export async function deleteObject<T>(path: string) {
    setAuthHeader()
    return await axios.delete<T>(path)
}
