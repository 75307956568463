import EditIcon from "@mui/icons-material/Edit";
import RedirectButton from "./RedirectButton";
import useAuth from "../../../functions/hooks/useAuth";
import {managementRoles} from "../../../functions/accessDefined";

export default function ModifyButton({path}:{path:string}) {
    const auth = useAuth()
    if(!auth.isAuthorized(managementRoles(), true)) return <></>

    return <RedirectButton icon={<EditIcon />} path={path} text={"Modify"} />
}
