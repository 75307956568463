import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import Paper from "@mui/material/Paper";
import {AssignmentStatus} from "../../../functions/entities/Assignment";
import {useBoard} from "../../../functions/hooks/useBoard";
import Typography from "@mui/material/Typography";
import AssignmentManagementCard from "./ManagementPanel";
import NewAssignment from "./NewAssignment";

const stackSx = {width: "18vw", mt: 1, mr: 1}

export function BoardLists() {
    return <>
        <Box className={"boardList"}>
            <Stack component={Paper} sx={stackSx}>
                <BoardSection title={"Backlog"} status={0}/>
            </Stack>
            <Stack component={Paper} sx={stackSx}>
                <BoardSection title={"Ongoing"} status={2}/>
            </Stack>
            <Stack component={Paper} sx={stackSx}>
                <BoardSection title={"Completed"} status={1}/>
            </Stack>
            <Stack component={Paper} sx={stackSx}>
                <BoardSection title={"Cancelled"} status={-1}/>
            </Stack>
        </Box>
    </>
}

function BoardSection({title, status}: { title: string, status: AssignmentStatus }) {
    const board = useBoard()

    return <Box className={"boardSection"}>
        <BoardTitle title={title}/>
        <NewAssignment status={status}/>
        {board.assignments.filter(e => e.status === status).map(e => <AssignmentManagementCard key={e.id} item={e}/>)}
    </Box>
}

function BoardTitle({title}: { title: string }) {
    return <Typography variant={"h5"}
                       sx={{background: "rgba(197,197,197,0.2)", p: 1, textAlign: "center"}}>{title}</Typography>
}
