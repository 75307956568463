import TextField from "@mui/material/TextField";
import RefreshButton from "../Buttons/RefreshButton";
import AddButton from "../Buttons/AddButton";
import useAuth from "../../../functions/hooks/useAuth";

export default function StandardListOptions({type, singleType, filter, setFilter, refresh} : {type:string, singleType:string, filter:string, setFilter: (e:string) => void, refresh: () => void}) {
    const auth = useAuth()

    return <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
        <div>
            <TextField variant="filled" label={"Filter"} sx={{mb: 2}} value={filter}
                       onChange={(e) => setFilter(e.target.value)}/>
        </div>
        <div>
            <RefreshButton refresh={refresh} />
            <auth.Authorized anyRole={true} roles={["LocalManagement", "UpperManagement"]}>
                <AddButton path={`/${type}/New`} text={`New ${singleType}`} />
            </auth.Authorized>
        </div>
    </div>
}
