import {Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import Paper from "@mui/material/Paper";
import Newsletter, {filterNewsletter} from "../../../functions/entities/Newsletter";
import LoadingTable from "../../particles/loading/Table";
import {useSnackbar} from "notistack";
import Error500 from "../Errors/500";
import {beautyDate} from "../../../functions/date";
import NoResults from "../../particles/Other/NoResults";
import AddButton from "../../particles/Buttons/AddButton";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import RefreshButton from "../../particles/Buttons/RefreshButton";
import RedirectButton from "../../particles/Buttons/RedirectButton";
import PeopleIcon from '@mui/icons-material/People';
import useGetCollection from "../../../functions/hooks/useGetObject";

export default function List() {
    const {enqueueSnackbar} = useSnackbar()
    const {loading, error, errorMsg, data, refresh} = useGetCollection<Newsletter>("/Newsletter/Get")
    const [filter, setFilter] = useState('')

    if(loading) return <>
        <NewsletterListOptions filter={filter} setFilter={setFilter} refresh={() => {}} />
        <LoadingTable columns={4} rows={20} />
    </>
    if(error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
        return <Error500 />
    }

    const filtered = data
        .filter(e => filterNewsletter(filter, e))

    return <>
        <NewsletterListOptions filter={filter} setFilter={setFilter} refresh={refresh} />
        {filtered.length === 0 ? <NoResults /> : <NewsletterTable data={filtered} />}
    </>
}

function NewsletterListOptions({filter, setFilter, refresh}:{filter:string, setFilter:(e:string) => void, refresh: () => void}) {
    return <div className={"flexElements spaceBetweenElements"}>
        <div>
            <TextField sx={{mb:1}} label={"Filter"} value={filter} onChange={e => setFilter(e.target.value)} />
        </div>
        <div>
            <RefreshButton refresh={refresh} />
            <RedirectButton text={"Subscribers"} path={"/Newsletters/Subscribers"} icon={<PeopleIcon />} />
            <AddButton path={"/Newsletters/New"} text={"New newsletter"} />
        </div>
    </div>
}

function NewsletterTable({data}:{data:Newsletter[]}) {
    const navigate = useNavigate()
    return <>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Subject</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Distributed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data
                        .sort((a,b) => b.id - a.id)
                        .map(e => <TableRow key={e.id} className={"clickableRow"} onClick={() => navigate(`/Newsletters/${e.id}`)}>
                        <TableCell>{e.subject}</TableCell>
                        <TableCell>{e.content.length}</TableCell>
                        <TableCell>{beautyDate(e.dateTime)}</TableCell>
                        <TableCell>{e.distributed === null ? <Chip label="No" color={"warning"} /> : beautyDate(e.distributed)}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
