import { JitsiMeeting } from '@jitsi/react-sdk';
import {useParams} from "react-router-dom";

//https://jitsi.org/blog/introducing-the-jitsi-meet-react-sdk/

export default function ReturnCall() {
    const { callname } = useParams<{ callname?:string }>();
    let finalCallName = ""

    finalCallName = createCallName(callname ?? "BQA-General-PRIV-");
    return <>
        <JitsiMeeting getIFrameRef = { node => node.style.height = '800px' } roomName={finalCallName } />
    </>
}
function createCallName(callNameTemp:string) {
    let newDate = new Date()
    let day = newDate.getDate();
    let month = newDate.getMonth() +1 ;
    let year = newDate.getFullYear();
    const actualCallName = callNameTemp + year + month + day
    return actualCallName;
}