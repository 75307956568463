import Typography from "@mui/material/Typography";
import clap from "./../../../media/img/clap.png"

export default function ClapMessage({message = "Congratulations, you're done!"} : {message?:string}) {
    return <>
        <div className={"flexElements verticalAlignCenter centerElements"}>
            <div style={{textAlign:"center"}}>
                <Typography color={"text.primary"} variant={"h3"} sx={{p:5}}>{message}</Typography>
                <img src={clap} alt={"clap"} style={{height: 100, width: 100, opacity: 0.7}} />
            </div>
        </div>
    </>
}
