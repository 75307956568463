import {Employee, employeeFullName} from "./Employee";
import {beautyDateLong} from "../date";

export default interface Log {
    id: number
    action: Action
    type:string
    info:string
    dateTime:Date
    user:Employee
}

export function filterLog(filter:string, log:Log) : boolean {
    if((filter+"").length === 0) return true
    if(getLogActionName(log.action).toLowerCase().includes(filter.toLowerCase())) return true
    if((log.type+"").toLowerCase().includes(filter.toLowerCase())) return true
    if((log.info+"").toLowerCase().includes(filter.toLowerCase())) return true
    if(beautyDateLong(log.dateTime).toLowerCase().includes(filter.toLowerCase())) return true
    return employeeFullName(log.user).toLowerCase().includes(filter.toLowerCase());
}

export type Action = 1 | 2 | 3

export function getLogActionName(value:Action) {
    switch (value) {
        case 1: return 'Add'
        case 2: return 'Modify'
        case 3: return 'Remove'
        default: return 'Undefined'
    }
}
