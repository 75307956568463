import {useState} from "react";
import useAuth from "../../../functions/hooks/useAuth";
import {authenticate} from "../../../functions/authenticationService";
import Box from "@mui/material/Box";
import "./../../../media/css/Login.scss"
import {Button, TextField} from "@mui/material";
import {useSnackbar} from "notistack";
import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";

export function Login() {
    const auth = useAuth()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    async function handleLogin(email: string, password: string) {
        setLoading(true)
        const authStatus = await authenticate(email, password)
        setLoading(false)
        if (authStatus.success) {
            auth.setToken(authStatus.token)
            console.log("AUTHENTICATION SUCCESSFUL")
            enqueueSnackbar("Successfully logged in.", {variant: "success"})
            //window.location.replace("/");
        } else {
            enqueueSnackbar(authStatus.message ?? "No error message has been found, but failed to login.", {variant: "error"})
        }
    }

    if(loading) return <LoadingLogin/>

    return <Box className={"login"}>
        <h2 className={"flexElements centerElements"}>Login</h2>
        <Box sx={{my: 1}}>
            <TextField sx={{mx: "auto", width: "100%"}} color={"primary"} variant={"outlined"} label={"Email"} type={"email"} onChange={e => setEmail(e.target.value)} value={email}/>
        </Box>
        <Box sx={{my: 1}}>
            <TextField sx={{mx: "auto", width: "100%"}} color={"primary"} variant={"outlined"} label={"Password"} type={"password"} onChange={e => setPassword(e.target.value)} value={password}/>
        </Box>
        <Box sx={{my: 1}} className={"flexElements centerElements"}>
            <Button color={"primary"} variant={"contained"} onClick={() => handleLogin(email, password)}>Login</Button>
        </Box>
    </Box>
}

function LoadingLogin() {
    return <Box className={"login"}>
        <h2 className={"flexElements centerElements"}>Login</h2>
        <CenteredLoadingCircle margin={5} />
    </Box>
}
