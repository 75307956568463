let lastRefresh = new Date().getTime()

export default async function voidCooldown(secondsCooldown: number, execute: () => void, enableConsole: boolean = true) {
    const COOLDOWN = secondsCooldown * 1000;
    if (new Date().getTime() > lastRefresh + COOLDOWN) {
        if (enableConsole) console.clear()
        await execute()
        lastRefresh = new Date().getTime()
    } else {
        if (enableConsole) {
            const difference = (new Date().getTime() - lastRefresh) / 1000
            console.log(`Refresh blocked, wait ${secondsCooldown} seconds. Time: ${Math.round(difference)}s`)
        }
    }
}
