import {WorkSession} from "../../../functions/entities/WorkSession";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useState} from "react";
import {beautyDateLong, timeBetweenStringShort} from "../../../functions/date";
import {employeeFullName} from "../../../functions/entities/Employee";
import {putObject} from "../../../functions/objectService";
import {useSnackbar} from "notistack";

export default function WorkSessionTable({data, displayName = true, disabled = true, displayModifyOptions = true} : {data: WorkSession[], displayName?: boolean, disabled?:boolean, displayModifyOptions?:boolean}) {
    return <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    {displayName ? <TableCell align="center">Employee</TableCell> : <></>}
                    <TableCell align="center">Started</TableCell>
                    <TableCell align="center">Ended</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(row => (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        {displayName ? <TableCell align="center">{row.user != null ? employeeFullName(row.user) : ''}</TableCell> : <></>}
                        <TableCell align="center">{beautyDateLong(row.started)}</TableCell>
                        <TableCell align="center">{beautyDateLong(row.ended)}</TableCell>
                        <TableCell align="center">{timeBetweenStringShort(row.started, row.ended)}</TableCell>
                        <TableCell align="center">
                            {displayModifyOptions ? <ToggleWorkSessionStatusButton id={row.id} disabled={disabled} status={row.status} /> : <WorkSessionStatusButton status={row.status} />}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

function ToggleWorkSessionStatusButton({id, status, disabled = true} : {id:number, status:(-1|0|1), disabled?:boolean}) {
    const [alignment, setAlignment] = useState(status+"")
    const {enqueueSnackbar} = useSnackbar()

    const handleChange = async (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment)
        try {
            await putObject<{ sessionId: number, status: (-1|0|1) }>("/HumanResources/Session", {
                sessionId: id,
                status: Number.parseInt(newAlignment) as (-1|0|1)
            })
            enqueueSnackbar("Successfully updated session.", {variant: "success"})
        } catch(e) {
            enqueueSnackbar(`${e}`, {variant: "error"})
        }
    };

    return <ToggleButtonGroup
        color={alignment === "0" ? "warning" : alignment === "1" ? "success" : "error"}
        value={alignment}
        exclusive
        disabled={disabled}
        onChange={handleChange}
    >
        <ToggleButton value="-1">Rejected</ToggleButton>
        <ToggleButton value="0">Pending</ToggleButton>
        <ToggleButton value="1">Approved</ToggleButton>
    </ToggleButtonGroup>
}


function WorkSessionStatusButton({status} : {status:(-1|0|1)}) {
    return <ToggleButtonGroup
        color={status === 0 ? "warning" : status === 1 ? "success" : "error"}
        value={""}
        exclusive
        disabled
    >
        <ToggleButton value={""}>{status === 0 ? "Pending" : status === 1 ? "Approved" : "Rejected"}</ToggleButton>
    </ToggleButtonGroup>
}
