import SearchIcon from '@mui/icons-material/Search';
import RedirectButton from "../Buttons/RedirectButton";
import useAuth from "../../../functions/hooks/useAuth";
import {logbookAccess} from "../../../functions/accessDefined";

export default function EmployeeLogsButton({userId}:{userId:string}) {
    const auth = useAuth()
    if(!auth.isAuthorized(logbookAccess(), true)) return <></>

    return <RedirectButton icon={<SearchIcon />} path={`/Logbook/${userId}`} text={"See logs"} />
}
