import {ToggleButtonGroup, useMediaQuery} from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import {wallViewOptions} from "../../../functions/theme/wallService";

export default function WallToggleButton({value, setValue}:{value:wallViewOptions, setValue:(e:wallViewOptions) => void}) {
    const smallScreen = useMediaQuery('(max-width: 1169px)')

    if(smallScreen) return <></>

    return <ToggleButtonGroup
        value={value}
        exclusive
        defaultValue={value}
        onChange={(e,v) => setValue(v)}
        aria-label="text alignment"
    >
        <ToggleButton value="1-column-left">
            <AlignHorizontalLeftIcon />
        </ToggleButton>
        <ToggleButton value="2-columns">
            <AlignHorizontalCenterIcon />
        </ToggleButton>
        <ToggleButton value="1-column-right">
            <AlignHorizontalRightIcon />
        </ToggleButton>
    </ToggleButtonGroup>
}
