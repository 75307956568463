import {Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Paper from "@mui/material/Paper";

let counter = 0
export default function LoadingTable({columns, rows} : {columns : Number, rows: Number}) {
    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {(Array(columns).fill(0)).map(() => <TableCell key={++counter}><Skeleton /></TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {(Array(rows).fill(0)).map(() => {
                    return <TableRow key={++counter}>
                        {(Array(columns).fill(0)).map(() => <TableCell key={++counter}><Skeleton/></TableCell>)}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}

export function LoadingTableFromHead({header, rows, align = "center"} : {header : string[], rows: Number, align?:("center"|"left"|"right")}) {
    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {header.map((title) => <TableCell sx={{textAlign: align}} key={++counter}>{title}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {(Array(rows).fill(0)).map(() => {
                    return <TableRow key={++counter}>
                        {header.map(() => <TableCell key={++counter}><Skeleton/></TableCell>)}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </TableContainer>
}
