import {Card, CardContent, SvgIconTypeMap} from "@mui/material";
import Typography from "@mui/material/Typography";
import {OverridableComponent} from "@mui/material/OverridableComponent";

export function SummaryCard({description, number, Icon}: {description:string, number:number|string|JSX.Element, Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>}) {
    return <Card sx={{width: "fit-content", m:1}}>
        <CardContent>
            <div className={"flexElements"}>
                <div>
                    <Icon sx={{fontSize: 70, color: "gray",opacity: 0.5, mr: 5, mt: 3, ml:1}} />
                </div>
                <div>
                    <Typography variant={"h1"} align={"right"} sx={{ml:2, fontSize: 80}}>{number}</Typography>
                </div>
            </div>
            <Typography variant={"body2"} color={"gray"} align={"right"}>{description}</Typography>
        </CardContent>
    </Card>
}