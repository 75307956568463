import {Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import Paper from "@mui/material/Paper";
import Log, {getLogActionName} from "../../../functions/entities/Log";
import {employeeFullName} from "../../../functions/entities/Employee";
import {beautyDate} from "../../../functions/date";
import NoResults from "../Other/NoResults";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {tablePages} from "../../../functions/table";

export default function LogbookTable({rows, page, filter, filtered, totalRows, handleRows, setFilter, handlePage} : {rows:number, filter:string, filtered:Log[], page:number, totalRows:number, handleRows:(e:number) => void, setFilter: (e:string) => void, handlePage:(e:number) => void}) {
    const navigate = useNavigate()

    return <>
        <div className={"flexElements spaceBetweenElements"}>
            <TextField margin={"dense"} variant={"filled"} label={"Rows"} type={"number"} value={rows} onChange={e => handleRows(Number.parseInt(e.target.value))} />
            <TextField margin={"dense"} variant={"filled"} label={"Filter"} value={filter} onChange={e => setFilter(e.target.value)} />
        </div>
        <TableContainer component={Paper}>
            <Table sx={{minWidth:650}}>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Info</TableCell>
                        <TableCell>Executor</TableCell>
                        <TableCell>Date</TableCell>
                    </TableRow>
                </TableHead>
                {filtered.length === 0 ? <></> : <TableBody>
                    {filtered.map(e => <TableRow key={e.id}>
                        <TableCell>{e.id}</TableCell>
                        <TableCell>{e.type}</TableCell>
                        <TableCell>{getLogActionName(e.action)}</TableCell>
                        <TableCell>{e.info}</TableCell>
                        <TableCell sx={{cursor: "pointer"}} onClick={() => navigate(`/Logbook/${e.user.id}`)}>{employeeFullName(e.user)}</TableCell>
                        <TableCell>{beautyDate(e.dateTime)}</TableCell>
                    </TableRow>)}
                </TableBody>}
            </Table>
            {filtered.length === 0 ? <NoResults /> : <></>}
            <Box className={"flexElements centerElements"} sx={{p:3}}>
                <Pagination count={tablePages(rows, totalRows)} variant="outlined" defaultValue={page} onChange={(e,v) => handlePage(v)} />
            </Box>
        </TableContainer>
    </>
}
