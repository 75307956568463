import {PaletteMode} from "@mui/material";
import * as React from "react";
import {createContext, useContext, useMemo, useState} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {getLocalTheme, setLocalTheme} from "../theme/localThemeService";
import {getDesignTokens} from "../theme/themeDesign";

export function useMyTheme() {
    return useContext(ColorModeContext)
}

export const ColorModeContext = createContext({
    toggle: () => {
    }, mode: getLocalTheme()
});

export function MyTheme({children}: { children: JSX.Element[] }) {
    const [mode, setMode] = useState<PaletteMode>(getLocalTheme());
    const colorMode = useMemo(
        () => ({
            toggle: () => {
                setLocalTheme(mode === 'light' ? 'dark' : 'light')
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
            mode: mode
        }),
        [mode],
    );

    const theme = useMemo(
        () =>
            createTheme(getDesignTokens(mode)), [mode],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
