import {useLocation} from "react-router-dom";
import ErrorPage from "./ErrorPage";

export default function Error408() {
    const location = useLocation()

    return <ErrorPage>
        We failed to make a connection to our server. Are you sure you have a solid internet connection?
        <br/>
        <small>Your path: <code>{location.pathname}</code></small>
    </ErrorPage>
}
