import {PaletteMode} from "@mui/material";

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        primary: {
            main: '#32944e',
        },
        secondary: {
            main: '#f50057',
        },
        success: {
            main: '#10d25c',
        },
        default: {
            main: '#fff',
        },
        ...(mode === 'light' && {
            primary: {
                main: '#32944e',
            },
            secondary: {
                main: '#f50057',
            },
            success: {
                main: '#10d25c',
            },
        }),
    },
});
