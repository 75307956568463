import {Card, CardActionArea, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {CloudFile} from "../../../functions/entities/CloudFile";
import bytesToReadable from "../../../functions/bytesToReadable";
import FileIcon from "./FileIcon";
import File from "../../../functions/entities/File";
import {beautyDate} from "../../../functions/date";
import {useState} from "react";
import FileDetailModal from "./FileDetailModal";

const fileNameLength = 15
export default function FileCard({file}: { file: CloudFile | File }) {
    const [openDetails, setOpenDetails] = useState(false)

    let tip = `File: ${file.name}, size: ${bytesToReadable(file.size)} bytes`
    if ("uploaded" in file) tip += `, uploaded: ${beautyDate(file.uploaded)}`
    return <>
        <Tooltip title={tip}>
            <Card sx={{m: 0.5}}>
                <CardActionArea onClick={() => setOpenDetails(true)}>
                    <Box className={"flexElements"} sx={{p: 3, py: 1, color: "text.secondary"}}>
                        <FileIcon file={file}/>
                        <Typography sx={{ml: 2}} variant={"subtitle1"} fontWeight={"bold"}>
                            {file.name.slice(0, fileNameLength)}
                            {file.name.length > fileNameLength && "..."}
                        </Typography>
                    </Box>
                </CardActionArea>
            </Card>
        </Tooltip>
        {"uploaded" in file && <FileDetailModal file={file} open={openDetails} handleClose={() => setOpenDetails(false)}/>}
    </>
}
