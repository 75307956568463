import Complaint from "../../../functions/entities/Complaint";
import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";
import {useSnackbar} from "notistack";
import Error500 from "../Errors/500";
import AddButton from "../../particles/Buttons/AddButton";
import {ComplaintCard} from "../../particles/Complaints/Card";
import NoResults from "../../particles/Other/NoResults";
import RefreshButton from "../../particles/Buttons/RefreshButton";
import RedirectButton from "../../particles/Buttons/RedirectButton";
import ReportIcon from "@mui/icons-material/Report";
import useAuth from "../../../functions/hooks/useAuth";
import {useEffect} from "react";
import {handleComplaints} from "../../../functions/accessDefined";
import useGetCollection, {useGetSingle} from "../../../functions/hooks/useGetObject";

let counter = 0
export default function ComplaintsIndex() {
    const {enqueueSnackbar} = useSnackbar()
    const {loading, error, errorMsg, data, refresh} = useGetCollection<Complaint>("/Complaint/Get")
    if (loading) return <CenteredLoadingCircle/>
    if (error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
        return <Error500/>
    }

    return <>
        <div className={"flexElements spaceBetweenElements"}>
            <div>
                <AllComplaintsButton />
            </div>
            <div>
                <RefreshButton refresh={refresh} />
                <AddButton path={"/HR/Complaints/New"} text={"Create complaint"}/>
            </div>
        </div>
        <div className={"flexElements centerElements"}>
            {data.length === 0
                ? <NoResults message={"No complaints found!"} />
                : <>{data.map(e => <ComplaintCard key={counter++} complaint={e} refresh={refresh}/>)}</>
            }
        </div>
    </>
}

function AllComplaintsButton() {
    const auth = useAuth()
    const {enqueueSnackbar} = useSnackbar()
    const {loading, data, error, errorMsg} = useGetSingle<number>("/Complaint/CountUnhandled", 0)

    useEffect(() => {
        if(data > 0) {
            enqueueSnackbar(`There are ${data} unhandled complaints!`, {variant: "warning"})
        }
    }, [data, enqueueSnackbar])

    if(loading) return <></>
    if(!auth.isAuthorized(handleComplaints())) return <></>
    if(error) {
        enqueueSnackbar(errorMsg, {variant: "error"})
    }

    return <RedirectButton text={`All Complaints (${data})`} path={"/HR/Complaints/All"} icon={<ReportIcon />} />
}
