import {useState} from "react";
import {Button} from "@mui/material";
import {Factory} from "../../../functions/entities/Factory";
import {DeleteFactoryDialog} from "./DeleteFactoryDialog";
import GoBackButton from "../Buttons/GoBackButton";
import ModifyButton from "../Buttons/ModifyButton";
import AddButton from "../Buttons/AddButton";

export default function FactoryDetailOptions({factory}: { factory: Factory }) {
    const [openDeleteMenu, setOpenDeleteMenu] = useState(false)
    return <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
        <div>
            <GoBackButton path={"/Factories"}/>
            <ModifyButton path={`/Factories/${factory.id}/Modify`}/>
            <AddButton text={"New Blueprint"} path={`/Factories/${factory.id}/Blueprint/New`}/>
            <AddButton text={"New Item"} path={`/Factories/${factory.id}/Items/New`}/>
        </div>
        <div>
            <Button variant={"contained"} color={"error"} onClick={() => setOpenDeleteMenu(true)} sx={{mb: 1}}>
                Delete Factory
            </Button>
            <DeleteFactoryDialog factory={factory} open={openDeleteMenu} closeMethod={() => setOpenDeleteMenu(false)}/>
        </div>
    </div>
}
