import {grey} from "@mui/material/colors";
import Post from "./Post";
import {VerticalTimeline} from "react-vertical-timeline-component";
import {wallViewOptions} from "../../../functions/theme/wallService";
import {useMyTheme} from "../../../functions/hooks/useMyTheme";
import {ExtendedWallPost} from "../../../functions/entities/WallPost";
import NoResults from "../Other/NoResults";

let counter = 0
export default function WallScroller({items, alignment}:{items:ExtendedWallPost[], alignment:wallViewOptions}) {
    const theme = useMyTheme()

    if(items.length === 0) return <>
        <NoResults message={"The wall seems to be a bit empty!"} />
    </>

    return <VerticalTimeline animate={false} layout={alignment}
                             lineColor={theme.mode === "light" ? "white" : grey[400]}>
        {items.map(e => <Post key={counter++} post={e}/>)}
    </VerticalTimeline>
}
