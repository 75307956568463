import useAuth from "../../../functions/hooks/useAuth";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import RedirectButton from "../Buttons/RedirectButton";
import {handleWorkSessions} from "../../../functions/accessDefined";

export default function VisitEmployeeWorkSessionsButton({employeeId}: {employeeId:string}) {
    const auth = useAuth()

    return <auth.Authorized roles={handleWorkSessions()} anyRole={true}>
        <RedirectButton icon={<WorkHistoryIcon />} path={`/HR/Sessions/${employeeId}`} text={"Worked hours"} />
    </auth.Authorized>
}
