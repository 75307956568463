import Map from "../../../particles/Factories/Blueprint/Map";
import useBlueprintEditor, {BlueprintEditorProvider} from "../../../../functions/hooks/useBlueprint";
import EntityList from "../../../particles/Factories/Blueprint/EntityList";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import EntityForm from "../../../particles/Factories/Blueprint/EntityForm";

export default function Modify() {
    return <BlueprintEditorProvider>
        <BlueprintEditor />
    </BlueprintEditorProvider>
}

export function BlueprintEditor() {
    const blueprintEditor = useBlueprintEditor()

    return <Paper className={"flexElements centerElements"} sx={{p: 1}}>
        <div id={"map"}>
            <Typography variant={"h6"}>Blueprint</Typography>
            <Map />
        </div>
        <div id={"buildings"} style={{paddingLeft: 10, paddingRight: 10}}>
            <Typography variant={"h6"}>Entities ({blueprintEditor.entities.length})</Typography>
            <EntityList />
        </div>
        <div id={"options"} style={{display: "flex", flexDirection: "column"}}>
            <Typography variant={"h6"}>Options</Typography>
            <EntityForm />
        </div>
    </Paper>
}
