import {useMemo, useState} from "react";
import useInterval from "./hooks/useInterval";

export function timeBetweenString(fromDate: Date, untilDate: Date): string {
    var diffMs = calculateDiffMs(fromDate, untilDate)
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
    return `${diffDays === 0 ? "" : diffDays + "d "}${diffHrs} hours ${diffMins} minutes`
}

export function timeBetweenStringShort(fromDate: Date, untilDate: Date): string {
    var diffMs = calculateDiffMs(fromDate, untilDate)
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
    return `${diffDays === 0 ? "" : diffDays + "d "}${diffHrs}h ${diffMins}m`
}

export function minutesBetween(fromDate: Date, untilDate: Date): number {
    var diffMs = calculateDiffMs(fromDate, untilDate)
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
    return diffMins + (diffHrs * 60) + (diffDays * 60 * 24)
}

export function beautyDate(date: Date) {
    return date.toString().replace("T", " ").substring(0, 19)
}

export function beautyDateLong(date: Date) {
    let dateString = date.toString().replace("T", " ")
    dateString = dateString.replace(new Date().toISOString().substring(0, 10), "Today at ")
    dateString = dateString.replace((new Date((new Date()).getTime() - 86400000)).toISOString().substring(0, 10), "Yesterday at ")
    const indexOfDot = dateString.indexOf('.')
    if (indexOfDot > 0) {
        dateString = dateString.substring(0, indexOfDot)
    }
    return dateString
}

function calculateDiffMs(fromDate: Date, untilDate: Date) {
    return (new Date(untilDate).getTime() - new Date(fromDate).getTime()) // milliseconds between now & then
}

export function getDateFormValue(date: Date) {
    return date.toJSON()
}

export interface DateOnly {
    day: number
    dayNumber: number
    dayOfWeek: number
    dayOfYear: number
    month: number
    year: number
}

export function defaultDateOnly(): DateOnly {
    const date = new Date()
    return {
        day: date.getDay(),
        dayNumber: 0,
        dayOfWeek: 0,
        dayOfYear: 0,
        month: date.getMonth(),
        year: date.getFullYear()
    }
}

export function dateOnlyToString(date: DateOnly, seperator: string = "-"): string {
    return `${date.year}${seperator}${(date.month + "").length === 1 ? "0" + date.month : date.month}${seperator}${date.day}`
}

export function secondsBetween(fromDate: Date, untilDate: Date) {
    return calculateDiffMs(fromDate, untilDate) / 1000 // milliseconds between now & then
}

export function datePlusMs(date: Date, ms: number) {
    return new Date(new Date(date).getTime() + ms)
}

export function useWaitForDate(recordedDate: Date, msWaiting: number) {
    const [currentTime, setCurrentTime] = useState(new Date())
    const result = useMemo(() => {
        return secondsBetween(recordedDate, datePlusMs(currentTime, msWaiting))
    }, [recordedDate, msWaiting, currentTime])
    useInterval(() => setCurrentTime(new Date()), 0.09)
    //console.log("Seconds " + recordedDate.getSeconds() + ", waiting: " + msWaiting + ", timeLeft: " + result)
    return result
}

export function useWaitForDateProgress(recordedDate: Date, msWaiting: number) {
    const timeSince = useWaitForDate(recordedDate, msWaiting)
    if (timeSince > (msWaiting * 1000)) return 100
    //console.log((timeSince / (msWaiting * 1000)) * 100000)
    const result = (timeSince / (msWaiting * 1000)) * 100000
    if (result > 100) return 100
    return result < 0 ? 0 : result
}
