import {beautyDateLong} from "../date";

export default interface Newsletter {
    id: number
    subject: string
    content: string
    receivers: number
    distributed:Date | null
    dateTime:Date
}

export function defaultNewsletter() : Newsletter {
    return {
        id: 0,
        subject: '',
        content: '',
        distributed: null,
        dateTime: new Date(),
        receivers: 0
    }
}

export function filterNewsletter(filter:string, letter:Newsletter) : boolean {
    if(filter.length === 0) return true
    if(letter.subject.toLowerCase().includes(filter.toLowerCase())) return true
    if(beautyDateLong(letter.dateTime).toLowerCase().includes(filter.toLowerCase())) return true
    if(letter.distributed !== null && beautyDateLong(letter.distributed).toLowerCase().includes(filter.toLowerCase())) return true
    return letter.content.toLowerCase().includes(filter.toLowerCase());

}
