import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button} from "@mui/material";

const textFieldStyling = {
    margin: 1
}

export default function TwoFactorAuth() {
    return <Box className={"flexElements centerElements"}>
        <div>
            <Typography variant={"h4"} sx={{pb: 3}}>2FA</Typography>
            <Box>
                QR Code
                <br />
                Or code
            </Box>
            <Button sx={textFieldStyling} variant={"contained"}>Enable 2FA</Button>
        </div>
    </Box>
}
