import {useEffect, useState} from "react";
import Log, {filterLog} from "../../../functions/entities/Log";
import LoadingTable from "../../particles/loading/Table";
import {useSnackbar} from "notistack";
import Error408 from "../Errors/408";
import Error500 from "../Errors/500";
import LogbookTable from "../../particles/Logbook/Table";
import voidCooldown from "../../../functions/voidCooldown";
import {useParams} from "react-router-dom";
import useGetCollection, {useGetSingle} from "../../../functions/hooks/useGetObject";

export default function Logbook() {
    const {userId} = useParams<{userId?:string}>()
    const {
        loading: loading2,
        error: error2,
        errorMsg: errorMsg2,
        data: totalRows,
        hasLoaded: hasLoaded2
    } = useGetSingle<number>(`/Logbook/Rows${userId == null ? '' : '/' + userId}`, -1)
    const {enqueueSnackbar} = useSnackbar()
    const [page, setPage] = useState(1)
    const [rows, setRows] = useState(10)
    const [filter, setFilter] = useState('')
    const {
        loading,
        error,
        errorMsg,
        data
    } = useGetCollection<Log>(`/Logbook/Get/${page}/${rows}${userId == null ? '' : '/' + userId}`)

    useEffect(() => {
        if (error) {
            enqueueSnackbar(`${errorMsg}`, {variant: "error"})
        }
        if (error2 || (totalRows < 0 && hasLoaded2)) {
            enqueueSnackbar(`${errorMsg2}`, {variant: "error"})
        }
    }, [enqueueSnackbar, error, error2, errorMsg, errorMsg2, totalRows, hasLoaded2])

    function handlePage(value: number) {
        if (value < 0) return;
        setPage(value)
    }

    async function handleRows(value: number) {
        if (rows > totalRows && rows < value) {
            await voidCooldown(2, () => enqueueSnackbar(`The maximum rows available has already been reached.`))
            return;
        }
        if (value < 5) {
            await voidCooldown(2, () => enqueueSnackbar(`The minimum rows available has already been reached.`))
            return;
        }
        if (rows < value) setRows(rows + 5)
        if (rows > value) setRows(rows - 5)
    }

    if (loading || loading2) return <LoadingTable columns={6} rows={rows}/>
    if (error) {
        return <Error408/>
    }
    if (error2 || totalRows < 0) {
        return <Error500/>
    }

    const filtered = data.filter(e => filterLog(filter, e))

    return <LogbookTable rows={rows}
                         page={page}
                         handleRows={handleRows}
                         handlePage={handlePage}
                         filter={filter}
                         filtered={filtered}
                         setFilter={setFilter}
                         totalRows={totalRows} />
}
