import {ExtendedWallPost} from "../../../functions/entities/WallPost";
import * as React from "react";
import WallScroller from "../Wall/WallScroller";
import {Tile} from "./HomeTile";
import LoadingWall from "../loading/LoadingWall";
import useGetCollection from "../../../functions/hooks/useGetObject";
import useInterval from "../../../functions/hooks/useInterval";

export default function WallTile() {
    const {data, loading, error, hasLoaded, refresh} = useGetCollection<ExtendedWallPost>('/Wall/Get/1/10')
    useInterval(refresh, error ? 60 : 20)

    if (loading && !hasLoaded) return <LoadingWall/>
    if (error) return <p>Failed to load wall. Retrying in less than 60 seconds...</p>
    return <Tile xs={12} md={6} lg={5} height={"80vh"}>
        <WallScroller items={data} alignment={"1-column-left"}/>
    </Tile>
}
