import {Route, Routes} from "react-router-dom";
import Error404 from "../../pages/Errors/404";
import React from "react";
import Board from "../../pages/Assignments/Board";

export default function AssignmentsRouter() {
    return <Routes>
        <Route path={"/"}>
            <Route index element={<Board />}/>
            <Route path="/*" element={<Error404/>}/>
        </Route>
    </Routes>
}
