import {Employee} from "../../../functions/entities/Employee";
import GoBackButton from "../Buttons/GoBackButton";
import ModifyButton from "../Buttons/ModifyButton";
import VisitEmployeeWorkSessionsButton from "../HR/VisitEmployeeWorkSessionsButton";
import EmployeeLogsButton from "./EmployeeLogsButton";
import DeleteEmployeeButton from "./DeleteEmployeeButton";
import RedirectButton from "../Buttons/RedirectButton";
import ReportIcon from "@mui/icons-material/Report";

export function EmployeeDetailOptions({employee}: { employee: Employee }) {
    return <div className={"flexElements spaceBetweenElements verticalAlignCenter"}>
        <div>
            <GoBackButton path={"/Employees"} />
            <EmployeeLogsButton userId={employee.id} />
            <VisitEmployeeWorkSessionsButton employeeId={employee.id} />
            <ModifyButton path={`/Employees/${employee.id}/Modify`} />
        </div>
        <div>
            <RedirectButton text={"Create complaint"} path={`/HR/Complaints/New/${employee.id}`} icon={<ReportIcon/>}
                            color={"warning"}/>
            <DeleteEmployeeButton employee={employee} />
        </div>
    </div>
}
