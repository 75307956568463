import {Building} from "../../../../functions/entities/Building";
import "./../../../../media/css/Blueprint.scss"
import useBlueprintEditor from "../../../../functions/hooks/useBlueprint";
import {useState} from "react";
import {Blueprint} from "../../../../functions/entities/Blueprint";
import {putObject} from "../../../../functions/objectService";
import voidCooldown from "../../../../functions/voidCooldown";

let counter = 0
let moving = false

export default function Map() {
    const bpContext = useBlueprintEditor()
    const [editedBuildings, setEditedBuildings] = useState<Building[]>(bpContext.entities)

    const blueprintHeight = (bpContext.blueprint.height / (bpContext.blueprint.height + bpContext.blueprint.width)) * (100)
    const blueprintWidth = (bpContext.blueprint.width / (bpContext.blueprint.height + bpContext.blueprint.width)) * (100)

    async function handleClick() {
        if (bpContext.selectedEntityId >= 0 && moving) {
            moving = false
            const entity = bpContext.getSelectedEntity()
            if (entity !== null) await putBuilding()
            bpContext.setSelectedEntity(-1)
        }
    }

    function handleMove(x: number, y: number) {
        if (bpContext.selectedEntityId >= 0) {
            moving = true
            try {
                const buildingIndex = editedBuildings.findIndex(e => e.id === bpContext.selectedEntityId)
                let oldBuilding = editedBuildings[buildingIndex]
                oldBuilding.xAxis = x - calculateBuildingWidth(oldBuilding.width, bpContext.blueprint)/2 ?? 0
                oldBuilding.yAxis = y - calculateBuildingHeight(oldBuilding.height, bpContext.blueprint)/2 ?? 0
                editedBuildings[buildingIndex] = oldBuilding
                setEditedBuildings(editedBuildings)
                bpContext.forceRefresh()
            } catch {

            }
        }
    }

    async function putBuilding() {
        const buildingIndex = editedBuildings.findIndex(e => e.id === bpContext.selectedEntityId)
        bpContext.forceRefresh()
        await putObject<Building>("/Blueprint/Building", editedBuildings[buildingIndex])
        bpContext.updateEntities()
    }

    return <div className={"map"}
                style={{width: blueprintWidth + "vh", height: blueprintHeight + "vh", margin: 5}}
                onMouseUp={handleClick}
                onMouseMove={(e) => voidCooldown(0.01, () => handleMove(e.clientX - e.currentTarget.offsetLeft, e.clientY - e.currentTarget.offsetTop), false)}
    >
        {editedBuildings.map(e => {
            return <BuildingDiv blueprint={bpContext.blueprint} selected={bpContext.selectedEntityId === e.id}
                                key={++counter} building={e}/>
        })}
    </div>
}

function BuildingDiv({building, selected, blueprint}: { building: Building, selected: boolean, blueprint: Blueprint }) {
    const blueprintEditor = useBlueprintEditor()
    let style
    if (selected) style = {
        left: building.xAxis,
        top: building.yAxis,
        width: calculateBuildingWidth(building.width, blueprint)+"vh",
        height: calculateBuildingHeight(building.height, blueprint)+"vh",
        backgroundColor: "orange"
    }
    else style = {
        left: building.xAxis,
        top: building.yAxis,
        width: calculateBuildingWidth(building.width, blueprint)+"vh",
        height: calculateBuildingHeight(building.height, blueprint)+"vh"
    }
    return <div
        onClick={() => blueprintEditor.setSelectedEntity(building.id)}
        className={"building"}
        style={style}
    >
        5M 4E
    </div>
}

function calculateBuildingWidth(width: number, blueprint: Blueprint): number {
    return (width / (blueprint.height + blueprint.width)) * 100
}

function calculateBuildingHeight(height: number, blueprint: Blueprint): number {
    return (height / (blueprint.height + blueprint.width)) * 100
}
