import 'react-vertical-timeline-component/style.min.css';
import {ExtendedWallPost} from "../../../functions/entities/WallPost";
import NoResults from "../../particles/Other/NoResults";
import CenteredLoadingCircle from "../../particles/loading/CenteredCircularProgress";
import Error500 from "../Errors/500";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import WallToggleButton from "../../particles/Wall/WallToggleButton";
import {getWallViewPreference, setWallViewPreference, wallViewOptions} from "../../../functions/theme/wallService";
import {Button} from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import Form from "../../particles/Wall/Form";
import WallScroller from "../../particles/Wall/WallScroller";
import useGetCollection, {usePolling} from "../../../functions/hooks/useGetObject";
import {ProgressUntilRefresh} from "../../particles/loading/ProgressUntilRefresh";

let latestPostsLength = -1
export default function WallPosts() {
    const {enqueueSnackbar} = useSnackbar()
    const [loadAll, setLoadAll] = useState(false)
    const [alignment, setAlignment] = useState<wallViewOptions>(getWallViewPreference())
    const {
        data,
        loading,
        error,
        refresh,
        hasLoaded,
        lastLoad
    } = useGetCollection<ExtendedWallPost>(loadAll ? '/Wall/Get' : '/Wall/Get/1/25')
    const {refreshRate} = usePolling(refresh, error, 10)

    useEffect(() => {
        if (latestPostsLength !== -1 && latestPostsLength < data.length) {
            enqueueSnackbar("A new post has been created!", {variant: "info"})
        }
        if (hasLoaded) latestPostsLength = data.length
    }, [enqueueSnackbar, data.length, hasLoaded])

    function modifyAlignment(e: wallViewOptions) {
        setWallViewPreference(e)
        setAlignment(e)
    }

    if (loading && !hasLoaded) return <CenteredLoadingCircle/>
    if (error && !hasLoaded) {
        return <Error500/>
    }

    return <>
        <div className={"flexElements spaceBetweenElements"}>
            <div></div>
            <WallToggleButton value={alignment} setValue={modifyAlignment}/>
        </div>
        <div className={"flexElements centerElements"}>
            <Form refresh={refresh}/>
        </div>
        {data.length === 0 ? <NoResults/> : <WallScroller alignment={alignment} items={data}/>}

        <div className={"flexElements centerElements"}>
            {!loadAll &&
                <Button onClick={() => (latestPostsLength = -1) && setLoadAll(true)}><AutorenewIcon/> Load All</Button>}
        </div>
        <ProgressUntilRefresh lastRefresh={lastLoad} msUntilRefresh={refreshRate}/>
    </>
}
